import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Proptypes from 'prop-types';
import { setContactInformation } from '../../actions/contact';
import { ValidateContactInfo } from '../../utils/validations/contact';
import InputField from '../utility/inputField';
import Title from '../utility/title';
import { formatPhoneNumber } from '../../utils/helper';
import HorizontalLine from '../utility/horizontalLine';
import { devices } from '../../utils/deviceType';
import { toggleEditMode } from '../../actions/section';

const INITIAL_VALIDATION_STATE = {
  name: {
    isValid: true,
    errorMessage: ''
  },
  businessName: {
    isValid: true,
    errorMessage: ''
  },
  address1: {
    isValid: true,
    errorMessage: ''
  },
  address2: {
    isValid: true,
    errorMessage: ''
  },
  city: {
    isValid: true,
    errorMessage: ''
  },
  state: {
    isValid: true,
    errorMessage: ''
  },
  zip: {
    isValid: true,
    errorMessage: ''
  },
  email: {
    isValid: true,
    errorMessage: ''
  },
  phoneNumber: {
    isValid: true,
    errorMessage: ''
  },
  phoneExt: {
    isValid: true,
    errorMessage: ''
  }
};

function Contact(props) {
  const {
    contactInfo,
    editMode,
    setContactInfo,
    switchEditMode
  } = props;

  const setEditMode = (editState) => (
    switchEditMode({
      contact: {
        editMode: editState
      }
    })
  );

  const [{
    name,
    businessName,
    address1,
    address2,
    city,
    state,
    zip,
    email,
    phoneNumber,
    phoneExt,
    isValidAddress
  },
  setState] = useState(contactInfo);

  const [validationList, setValidationList] = useState(INITIAL_VALIDATION_STATE);

  useEffect(() => {
    if (contactInfo) {
      setState(contactInfo);
    }
  }, [contactInfo, setState]);

  // The below code should be moved to store
  const titleText = 'Contact Information';

  const displayContactItem = (value) => (value.length > 50
    ? `${value.substring(0, 49)}...` : value);

  const formContactItem = (item) => (
    <>
      <ContactInfoReadOnlyItem>
        <ContactInfoField>{item.name}</ContactInfoField>
        <ContactInfoValue>{ item.name !== 'Billing address' ? displayContactItem(item.value == null ? '' : item.value) : item.value}</ContactInfoValue>
      </ContactInfoReadOnlyItem>
      <HorizontalLine thickness="1x" type="special" />
    </>
  );

  const readOnlyView = () => {
    let billingAddress = '';
    if (contactInfo?.address1 !== null
      && contactInfo?.city !== null
      && contactInfo?.state
      && contactInfo?.zip) {
      billingAddress = `${contactInfo?.address1}${(contactInfo?.address2 !== undefined && contactInfo?.address2 !== '') ? `, ${contactInfo?.address2}` : ''}, ${contactInfo?.city}, ${contactInfo?.state} ${contactInfo?.zip}`;
    }

    const phoneNumberWithExt = `${contactInfo?.phoneNumber?.displayValue} ${contactInfo?.phoneExt !== null && contactInfo?.phoneExt !== '' ? `Ext. ${contactInfo?.phoneExt}` : ''}`;

    return (
      <ContactInfoReadOnlyContainer>
        {formContactItem({ name: 'Name', value: contactInfo?.name })}
        {formContactItem({ name: 'Company name', value: contactInfo?.businessName })}
        {formContactItem({ name: 'Billing address', value: billingAddress })}
        {formContactItem({ name: 'Email address', value: contactInfo?.email })}
        {formContactItem({ name: 'Phone number', value: phoneNumberWithExt })}
      </ContactInfoReadOnlyContainer>
    );
  };

  const ToggleValidationMessageIfEligible = (fieldValue, fieldName) => {
    if (fieldValue !== '') {
      setValidationList({
        ...validationList,
        [fieldName]: {
          ...INITIAL_VALIDATION_STATE[fieldName]
        }
      });
    }
  };

  const handleFormValueChange = (e) => {
    const regStrictDigitCheck = /^[0-9\b]+$/;
    const regStrictAlphabetCheck = /^[A-Za-z]+$/;

    const fieldName = e.target.name;
    let fieldValue = e.target.value;

    // Turn off any active validation message that is associated to the
    // field if the changed value is non-empty
    ToggleValidationMessageIfEligible(fieldValue, fieldName);

    if (fieldName.toLowerCase() === 'phonenumber') {
      const plainPhValue = fieldValue.replace(/[^\d]/g, '');

      if (plainPhValue.length < 11) {
        fieldValue = {
          value: plainPhValue, // we need to strip off the symbols here
          displayValue: formatPhoneNumber(plainPhValue)
        };

        setState((existingState) => ({
          ...existingState,
          [fieldName]: fieldValue
        }));
      }
      return;
    }

    if (fieldValue !== '') {
      if (fieldName === 'zip' || fieldName === 'phoneExt') {
        if (!regStrictDigitCheck.test(fieldValue)) {
          return;
        }
      }

      if (fieldName === 'state') {
        if (!regStrictAlphabetCheck.test(fieldValue)) {
          return;
        }
        fieldValue = fieldValue.toUpperCase();
      }
    }

    // Any changes in the contactinfo - reset the scrubbed address validation
    let isContactInfoModified = isValidAddress;
    if (fieldName === 'address1' || fieldName === 'address2' || fieldName === 'state' || fieldName === 'zip'
      || fieldName === 'city') {
      isContactInfoModified = false;
    }

    setState((existingState) => ({
      ...existingState,
      [fieldName]: fieldValue,
      isValidAddress: isContactInfoModified
    }));
  };

  const ValidateContactInformation = () => {
    const validationResult = ValidateContactInfo({
      name,
      businessName,
      address1,
      address2,
      city,
      state,
      zip,
      email,
      phoneNumber,
      phoneExt
    }, INITIAL_VALIDATION_STATE);

    if (!validationResult.isValid) {
      setValidationList({
        ...validationList,
        ...validationResult.updatedValidationList
      });
      return false;
    }

    return true;
  };

  const dataPreparations = () => {
    const tName = name.trim();
    const tBusinessName = businessName.trim();
    const tAddress1 = address1.trim();
    const tAddress2 = address2?.trim();
    const tCity = city.trim();
    const tState = state.trim();
    const tZip = zip.trim();
    const tEmail = email.trim();
    const tPhoneNumber = phoneNumber;
    const tPhoneExt = phoneExt;
    const tIsValidAddress = isValidAddress;

    return {
      name: tName,
      businessName: tBusinessName,
      address1: tAddress1,
      address2: tAddress2,
      city: tCity,
      state: tState,
      zip: tZip,
      email: tEmail,
      phoneNumber: tPhoneNumber,
      phoneExt: tPhoneExt,
      isValidAddress: tIsValidAddress
    };
  };

  const handleSave = () => {
    // form validation
    if (!ValidateContactInformation()) {
      return;
    }

    const finalContactInfo = dataPreparations();

    setContactInfo({
      ...contactInfo,
      name: finalContactInfo.name,
      businessName: finalContactInfo.businessName,
      address1: finalContactInfo.address1,
      address2: finalContactInfo.address2,
      city: finalContactInfo.city,
      state: finalContactInfo.state,
      zip: finalContactInfo.zip,
      email: finalContactInfo.email,
      phoneNumber: finalContactInfo.phoneNumber,
      phoneExt: finalContactInfo.phoneExt,
      isValidAddress: finalContactInfo.isValidAddress
    });

    setValidationList(INITIAL_VALIDATION_STATE);
    setEditMode(false);
  };

  const handleCancel = () => {
    // Resetting back to initial state
    setContactInfo({
      ...contactInfo
    });

    setValidationList(INITIAL_VALIDATION_STATE);
    setEditMode(false);
  };

  const editView = () => (
    <ContactInfoEditModeContainer>
      <FormContainer>
        <InputField id="name" name="name" type="text" labelName="Name" maxLength={50} isRequired onChange={handleFormValueChange} value={name} isValid={validationList.name.isValid} errorMessage={validationList.name.errorMessage} inputFieldStyle="special" />
        <InputField id="businessName" name="businessName" labelName="Company name" type="text" maxLength={50} isRequired onChange={handleFormValueChange} value={businessName} isValid={validationList.businessName.isValid} errorMessage={validationList.businessName.errorMessage} inputFieldStyle="special" />
        <InputField id="address1" name="address1" labelName="Address line 1" type="text" maxLength={70} isRequired onChange={handleFormValueChange} value={address1} isValid={validationList.address1.isValid} errorMessage={validationList.address1.errorMessage} inputFieldStyle="special" />
        <InputField id="address2" name="address2" labelName="Address line 2" type="text" maxLength={70} onChange={handleFormValueChange} value={address2} inputFieldStyle="special" />
        <InputField id="city" name="city" labelName="City" type="text" maxLength={25} isRequired onChange={handleFormValueChange} value={city} isValid={validationList.city.isValid} errorMessage={validationList.city.errorMessage} inputFieldStyle="special" />
        <InputField id="state" name="state" labelName="State/province" type="text" maxLength={2} isRequired onChange={handleFormValueChange} value={state} isValid={validationList.state.isValid} errorMessage={validationList.state.errorMessage} inputFieldStyle="special" />
        <InputField id="zip" name="zip" labelName="ZIP code" type="text" maxLength={5} isRequired onChange={handleFormValueChange} value={zip} isValid={validationList.zip.isValid} errorMessage={validationList.zip.errorMessage} inputFieldStyle="special" />
        <InputField id="email" name="email" labelName="Email address" type="text" maxLength={50} isRequired onChange={handleFormValueChange} value={email} isValid={validationList.email.isValid} errorMessage={validationList.email.errorMessage} inputFieldStyle="special" />
        <InputField id="phoneNumber" name="phoneNumber" labelName="Phone number" type="text" maxLength={14} isRequired onChange={handleFormValueChange} value={phoneNumber.displayValue} isValid={validationList.phoneNumber.isValid} errorMessage={validationList.phoneNumber.errorMessage} inputFieldStyle="special" />
        <InputField id="phoneExt" name="phoneExt" labelName="Phone ext." type="text" maxLength={5} onChange={handleFormValueChange} value={phoneExt} inputFieldStyle="special" />
      </FormContainer>
    </ContactInfoEditModeContainer>
  );

  return (
    <ContactContainer>
      <Title
        titleText={titleText}
        editMode={editMode}
        switchToEditMode={() => setEditMode(true)}
        onSave={handleSave}
        onCancel={handleCancel}
      />
      <ContactSubContainer>
        {
          editMode ? editView() : readOnlyView()
        }
      </ContactSubContainer>
    </ContactContainer>
  );
}

const ContactContainer = styled.div`
  margin: 0 0 2em 0;
`;

const ContactSubContainer = styled.div`
  padding: 1em 4em;

  @media only screen and ${devices.mobileS} {
    padding: 1em 2em;
  }
    
  @media only screen and ${devices.mobileM} {
    padding: 1em 2em;
  }
    
  @media only screen and ${devices.mobileL} {
    padding: 1em 2em;
  }
    
  @media only screen and ${devices.tablet} {
    padding: 1em 3em;
  }
    
  @media only screen and ${devices.laptop} {
    padding: 1em 4em;
  }
`;

const ContactInfoReadOnlyContainer = styled.div``;

const ContactInfoReadOnlyItem = styled.div`
  display: flex;  
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ContactInfoField = styled.div``;

const ContactInfoValue = styled.div`
  text-align: right;  
  word-break: break-all;
`;

const ContactInfoEditModeContainer = styled.div``;

const FormContainer = styled.form``;

Contact.propTypes = {
  contactInfo: Proptypes.shape({
    name: Proptypes.string,
    businessName: Proptypes.string,
    address1: Proptypes.string,
    address2: Proptypes.string,
    city: Proptypes.string,
    state: Proptypes.string,
    zip: Proptypes.string,
    email: Proptypes.string,
    phoneNumber: Proptypes.shape({
      displayValue: Proptypes.string,
      value: Proptypes.string
    }),
    phoneExt: Proptypes.string
  }),
  editMode: Proptypes.bool,
  setContactInfo: Proptypes.func,
  switchEditMode: Proptypes.func
};

const mapStateToProps = (state) => ({
  contactInfo: state.contactInfo,
  editMode: state.sectionInfo?.contact?.editMode
});

const mapDispatchToProps = {
  setContactInfo: setContactInformation,
  switchEditMode: toggleEditMode
};

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
