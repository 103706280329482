import { TOGGLE_EDIT_MODE } from '../actions/section';

const INITIAL_STATE = {
  contact: {
    editMode: false
  },
  lines: {
    editMode: false
  },
  devices1: {
    editMode: false
  },
  savings: {
    editMode: false
  }
};

const sectionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TOGGLE_EDIT_MODE: {
      const { section } = action;
      return {
        ...state,
        ...section
      };
    }
    default:
      return state;
  }
};

export default sectionReducer;
