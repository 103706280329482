import styled from 'styled-components';
import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

function SearchableDropdown(props) {
  const {
    options,
    dropdownSelectedValue,
    onChange,
    isValid,
    errorMessage
  } = props;

  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(dropdownSelectedValue);
  const [filterText, setFilterText] = useState('');
  const inputRef = useRef();
  const spanRef = useRef();
  const listItemRef = useRef();

  useEffect(() => {
    setSelectedValue(dropdownSelectedValue);
  }, [dropdownSelectedValue]);

  const close = (e) => {
    if (
      e
      && !(e.target === inputRef.current || e.target === spanRef.current)
      && open
    ) {
      setOpen(false);
      if (options.findIndex((x) => x.name === selectedValue) === -1) {
        setSelectedValue('');
      }
      setFilterText('');
    }
  };

  useEffect(() => {
    document.addEventListener('click', close);
    return () => (document.removeEventListener('click', close));
  }, [inputRef, spanRef, listItemRef, open, selectedValue]);

  const onOptionSelection = (option) => {
    if (option?.productCode === '-1') {
      onChange(null);
      setSelectedValue('');
    } else {
      onChange(option);
      setSelectedValue(option.name);
    }
    setFilterText('');
    setOpen(!open);
  };

  const displayOptions = (filteredOptions) => (
    <DropdownOptions>
      {filteredOptions.length > 0 ? (
        filteredOptions.map((x, i) => (
          <ListItem
            ref={listItemRef}
            key={i}
            id={i}
            onClick={() => onOptionSelection(x)}
          >
            {x.name}
          </ListItem>
        ))
      )
        : null}
    </DropdownOptions>
  );

  const filteredOptions = () => {
    if (filterText === '') {
      return [{ productCode: '-1', name: 'Select Device brand' }, ...options];
    }
    return [{ productCode: '-1', name: 'Select Device brand' }, ...options.filter((x) => x.name.toLowerCase().indexOf(filterText.toLowerCase()) !== -1)];
  };

  const handleOnChange = (e) => {
    onChange(null);
    setOpen(true);
    setSelectedValue('');
    setFilterText(e.target.value);
  };

  const getDisplayValue = () => {
    if (filterText !== '') return filterText;
    if (selectedValue) return selectedValue;
    return '';
  };

  return (
    <SearchableDropdownContainer>
      <SearchableContainer isValid={isValid}>
        <InputContainer open={open}>
          <InputField
            ref={inputRef}
            placeholder="Select Device brand"
            value={getDisplayValue()}
            onClick={() => (!open ? setOpen(true) : null)}
            onChange={handleOnChange}
          />
          <Opener
            ref={spanRef}
            onClick={() => {
              inputRef.current.focus();
              setOpen(!open);
            }}
          >
            {open ? '<' : '>'}
          </Opener>
        </InputContainer>
        {open ? displayOptions(filteredOptions()) : ''}
      </SearchableContainer>
      {
        !isValid
          ? (
            <ValidationMessageContainer>
              {errorMessage}
            </ValidationMessageContainer>
          )
          : null
      }
    </SearchableDropdownContainer>
  );
}

const SearchableDropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const SearchableContainer = styled.div`
  width: 100%;
  display: flex;  
  flex-direction: column;
  justify-content: space-between;
  align-items: center;  
  color: ${({ theme }) => (theme.searchableDropdown.color)};
  border: 2px solid ${({ theme }) => (theme.searchableDropdown.color)};
  position: relative; 
  min-width: 10em;
  background-color: ${({ theme }) => (theme.searchableDropdown.backgroundColor)};
  border: ${({ isValid }) => (!isValid ? '1px solid red' : '')};
  width: 100%;
`;

const InputContainer = styled.div`
  height: 1.8em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;  
  overflow: hidden; 
`;

const InputField = styled.input`
  font-size: 1em;
  border: none;
  outline: none;
  width: 85%;
  height: 95%;
  padding: .3em;
  margin: 0;
  color: ${({ theme }) => (theme.searchableDropdown.color)};
  background-color: ${({ theme }) => (theme.searchableDropdown.backgroundColor)};
`;

const Opener = styled.div`  
  color: ${({ theme }) => (theme.searchableDropdown.color)};
  margin: 0 .3em;
  width: 10%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(90deg);  
  background-color: ${({ theme }) => (theme.searchableDropdown.backgroundColor)};
`;

const DropdownOptions = styled.ul`  
  color: ${({ theme }) => (theme.searchableDropdown.color)};
  max-height: 10em;
  overflow-y: scroll;
  border: .125em solid ${({ theme }) => (theme.searchableDropdown.color)};
  list-style: none;
  padding: 0;
  margin: 2em 0 0 0;
  width: 100%;
  position: absolute;
  z-index: 1;
  background-color: ${({ theme }) => (theme.searchableDropdown.backgroundColor)};
`;

const ListItem = styled.li`
  border-bottom: .5px dashed grey;
  padding: .2em .4em;
  cursor: pointer;
  :hover {
    background-color: ${({ theme }) => (theme.searchableDropdown.hoverColor)};
    color: white;
  }
`;

const ValidationMessageContainer = styled.div`
  color: red;
  width: 100%;
  font-size: .85em;
  text-align: left;
  white-space: nowrap;
`;

SearchableDropdown.propTypes = {
  options: PropTypes.array,
  dropdownSelectedValue: PropTypes.string,
  onChange: PropTypes.func,
  isValid: PropTypes.bool,
  errorMessage: PropTypes.string
};

export default SearchableDropdown;
