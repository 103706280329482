export const formatPhoneNumber = (tn) => {
  if (tn.length < 4) {
    return `${tn}`;
  }
  if (tn.length < 7) {
    return `(${tn.slice(0, 3)}) ${tn.slice(3)}`;
  }
  if (tn.length < 11) {
    return `(${tn.slice(0, 3)}) ${tn.slice(3, 6)}-${tn.slice(6)}`;
  }

  return tn;
};

export const calculateTotalLinesAndDevices = (payload) => {
  const { quote: { previousQuote: { unlimitedType } } } = payload;
  const { quote: { previousQuote } } = payload;
  let totalLines = previousQuote.unlimited.quantity + previousQuote.byTheGig.quantity;
  if (unlimitedType === 1) {
    totalLines = previousQuote.unlimitedIntro.quantity + previousQuote.byTheGig.quantity;
  }
  const totalNumberOfPcatDevices = previousQuote
    .devices
    .reduce((a, b) => ({ units: a.units + b.units }), { units: 0 })?.units;
  const totalDevices = totalNumberOfPcatDevices + previousQuote.byod.quantity;

  return {
    totalLines,
    totalDevices
  };
};
