import PropTypes from 'prop-types';
import { useContext } from 'react';
import { connect } from 'react-redux';
import styled, { css, ThemeContext } from 'styled-components';
import { toggleTheme } from '../../actions/theme';

function ThemeButton(props) {
  const theme = useContext(ThemeContext);

  const {
    switchTheme
  } = props;

  return (
    <ThemeSwitcher onClick={() => switchTheme()}>
      <ModeButton>
        {theme.themeButton.icon}
      </ModeButton>
    </ThemeSwitcher>
  );
}

const CommonStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ThemeSwitcher = styled.div`
  cursor: pointer;
  width: 3em;
  height: 3em;
  border-radius: 50%;  
  background-color: ${({ theme }) => theme.themeButton.backgroundColor};
  ${CommonStyles}
  -webkit-box-shadow: 0px 0px 65px 25px ${({ theme }) => theme.themeButton.shadow};
  -moz-box-shadow: 0px 0px 65px 25px ${({ theme }) => theme.themeButton.shadow};
  box-shadow: 0px 0px 65px 25px ${({ theme }) => theme.themeButton.shadow};
`;

const ModeButton = styled.div`
  font-size: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => (theme.themeButton.color)};
`;

ThemeButton.propTypes = {
  switchTheme: PropTypes.func
};

const mapDispatchToProps = {
  switchTheme: toggleTheme
};

export default connect(null, mapDispatchToProps)(ThemeButton);
