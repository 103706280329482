import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import InputField from '../utility/inputField';
import Dropdown from '../utility/dropdown';

function Byod(props) {
  const {
    editMode,
    unitsDropdown,
    byodInfo,
    setByodInfo,
    validationList,
    setValidationList
  } = props;

  const handleIMEIChange = (updatedIMEI, index) => {
    const onlyNumbersRegex = /^\d+$/;
    if (updatedIMEI !== '' && !onlyNumbersRegex.test(updatedIMEI)) {
      return;
    }

    const updatedIMEIList = byodInfo.imei.map((imei, i) => {
      if (i === index) {
        return {
          id: i,
          imei: updatedIMEI,
          isValid: true
        };
      }
      return imei;
    });

    setByodInfo({
      ...byodInfo,
      imei: updatedIMEIList
    });

    // reset the validation item
    const updatedByodValidationList = [...validationList.byodValidationList];
    updatedByodValidationList[index] = {
      isValid: true,
      errorMessage: ''
    };
    setValidationList({
      ...validationList,
      byodValidationList: updatedByodValidationList
    });
  };

  const prepareUpdatedIMEIList = (updatedValue) => {
    const {
      byod,
      imei
    } = byodInfo;

    const updatedImeiList = [...imei];
    const updatedByodValidationList = [...validationList.byodValidationList];

    if (updatedValue > byod) {
      let id = byod;
      while (id < updatedValue) {
        updatedImeiList.push({
          id,
          imei: '',
          isValid: false
        });
        // add a new validation list item
        updatedByodValidationList.push({
          isValid: true,
          errorMessage: ''
        });
        id++;
      }
    } else {
      let id = byod;
      while (id > updatedValue) {
        updatedImeiList.pop();
        // pop out the validation item
        updatedByodValidationList.pop();
        id--;
      }
    }

    setValidationList({
      ...validationList,
      byodValidationList: updatedByodValidationList
    });
    return updatedImeiList;
  };

  const handleByodDeviceChange = (e) => {
    const updatedValue = +e.target.value;
    const updatedImeiList = prepareUpdatedIMEIList(updatedValue);

    setByodInfo({
      ...byodInfo,
      byod: updatedValue,
      imei: updatedImeiList
    });
  };

  return (
    <ByodDevicesContainer>
      <ByodDevicesSubContainer>
        <ByodDevicesFieldContainer>
          <QuestionContainer>
            How many devices do you currently own that you would like to
            start using with Comcast Business Mobile?
          </QuestionContainer>
        </ByodDevicesFieldContainer>
        <ByodDevicesSelectionContainer>
          <Dropdown
            name="byodDevices"
            selectedValue={byodInfo.byod}
            options={unitsDropdown}
            onChange={handleByodDeviceChange}
            disabled={!editMode}
          />
        </ByodDevicesSelectionContainer>
      </ByodDevicesSubContainer>
      {
        byodInfo.byod > 0
          ? (
            <ImeiEntryContainer>
              <Description>
                Enter each phone&apos;s IMEI, or preferably its IMEI2 if it has one.
                You can find this in your phone&apos;s settings.
              </Description>
              <ByodDevicesTable>
                <ByodDevicesTableHeading>
                  <ByodDevicesTableRow>
                    <ByodDevicesTableHeader>IMEI</ByodDevicesTableHeader>
                  </ByodDevicesTableRow>
                </ByodDevicesTableHeading>
                <ByodDevicesTableBody>
                  {
                    byodInfo.imei.map((imei, i) => (
                      <ByodDevicesTableRow key={imei.id}>
                        <ByodDevicesTableColumn>
                          <InputField
                            id={`imei-${i}`}
                            name={`imei-${i}`}
                            type="text"
                            inputType="number"
                            placeholder="Enter IMEI"
                            maxLength={15}
                            isRequired
                            onChange={(e) => handleIMEIChange(e.target.value, i)}
                            value={imei?.imei}
                            isValid={validationList.byodValidationList[i].isValid}
                            errorMessage={validationList.byodValidationList[i].errorMessage}
                            inputFieldStyle="normal"
                            disabled={!editMode}
                          />
                        </ByodDevicesTableColumn>
                      </ByodDevicesTableRow>
                    ))
                  }
                </ByodDevicesTableBody>
              </ByodDevicesTable>
            </ImeiEntryContainer>
          )
          : null
      }
    </ByodDevicesContainer>
  );
}

const ImeiEntryContainer = styled.div``;

const QuestionContainer = styled.p``;

const Description = styled.p``;

const ByodDevicesSelectionContainer = styled.div``;

const ByodDevicesSubContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ByodDevicesContainer = styled.div`
  padding: 0 0 1em 0;
`;

const ByodDevicesFieldContainer = styled.div``;

const ByodDevicesTable = styled.table``;

const ByodDevicesTableRow = styled.tr``;

const ByodDevicesTableHeader = styled.th`
  font-weight: 600;
`;

const ByodDevicesTableHeading = styled.thead`
  text-align: left;
`;

const ByodDevicesTableBody = styled.tbody`
  color: ${({ theme }) => theme.table.body.color};
`;

const ByodDevicesTableColumn = styled.td``;

Byod.propTypes = {
  editMode: PropTypes.bool,
  unitsDropdown: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string
    })
  ),
  byodInfo: PropTypes.shape({
    byod: PropTypes.number,
    imei: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      imei: PropTypes.string,
      isValid: PropTypes.bool
    }))
  }),
  setByodInfo: PropTypes.func,
  validationList: PropTypes.shape({
    deviceValidationList: PropTypes.arrayOf(PropTypes.shape({
      device: PropTypes.shape({
        isValid: PropTypes.bool,
        errorMessage: PropTypes.string
      }),
      payment: PropTypes.shape({
        isValid: PropTypes.bool,
        errorMessage: PropTypes.string
      }),
      unit: PropTypes.shape({
        isValid: PropTypes.bool,
        errorMessage: PropTypes.string
      })
    })),
    byodValidationList: PropTypes.arrayOf(PropTypes.shape({
      isValid: PropTypes.bool,
      errorMessage: PropTypes.string
    }))
  }),
  setValidationList: PropTypes.func
};

const mapStateToProps = (state) => ({
  unitsDropdown: state.fieldInfo?.unitsDropdown
});

export default connect(mapStateToProps)(Byod);
