import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { setCurrentMonthlyBill } from '../../actions/savings';
import Dropdown from '../utility/dropdown';
import InputField from '../utility/inputField';
import Title from '../utility/title';
import { devices as deviceTypes } from '../../utils/deviceType';
import { toggleEditMode } from '../../actions/section';

function CurrentMonthlyBill(props) {
  const {
    savingsInfo,
    currentMobileProviderOptions,
    setCMB,
    editMode,
    switchToEditMode
  } = props;
  const [{ currentMonthlyBill, currentMobileProvider }, setSavings] = useState(savingsInfo);

  useEffect(() => {
    if (savingsInfo) {
      setSavings(savingsInfo);
    }
  }, [savingsInfo, setSavings]);

  const setEditMode = (editState) => (
    switchToEditMode({
      savings: {
        editMode: editState
      }
    })
  );

  // The below code should be moved to store
  const titleText = 'Current Mobile Bill';

  const onCMPChange = (data) => {
    setSavings({
      currentMonthlyBill,
      currentMobileProvider: +data.target.value
    });
  };

  const currentMobileProviderDropdown = () => (editMode ? (
    <Dropdown
      id="currentMobileProvider"
      name="currentMobileProvider"
      selectedValue={currentMobileProvider}
      onChange={(e) => onCMPChange(e)}
      disabled={!editMode}
      options={currentMobileProviderOptions}
      type="sleek"
    />
  ) : (
    <InputField
      name="currentMobileProvider"
      id="currentMobileProvider"
      type="text"
      placeholder="Select Current Provider"
      value={
          currentMobileProviderOptions?.find(
            (c) => c.id === currentMobileProvider
          )?.name ?? ''
        }
      inputFieldStyle="normal"
      disabled={!editMode}
    />
  ));

  const onMonthlyBillChange = (e) => {
    const { value } = e.target;
    const regexp = /^((?!0)\d{1,10}|0|\.\d{1,2})($|\.$|\.\d{1,2}$)/gm;

    if (regexp.test(value) || value === '') {
      setSavings({
        currentMobileProvider,
        currentMonthlyBill: value
      });
    }
  };

  const onBlurMonthlyBill = (e) => {
    if (e.target.value === '') {
      setSavings({
        currentMobileProvider,
        currentMonthlyBill: 0
      });
    } else {
      setSavings({
        currentMobileProvider,
        currentMonthlyBill: parseFloat(e.target.value).toFixed(2)
      });
    }
  };

  const onFocusMonthlyBill = (e) => {
    if (e.target.value === '0') {
      setSavings({
        currentMobileProvider,
        currentMonthlyBill: ''
      });
    }
  };

  const onCancel = () => {
    setEditMode(false);
    setSavings({
      ...savingsInfo
    });
  };

  const currentMonthlyBillInputField = () => (
    <div>
      <InputField
        id="currentMonthlyBill"
        value={currentMonthlyBill.toString()}
        onChange={(e) => onMonthlyBillChange(e)}
        maxLength={10}
        inputFieldStyle="currency"
        onBlur={(e) => onBlurMonthlyBill(e)}
        onFocus={(e) => onFocusMonthlyBill(e)}
        disabled={!editMode}
      />
    </div>
  );

  const savingsView = () => (
    <CMBTableRow>
      <CMBTableColumn>{currentMonthlyBillInputField()}</CMBTableColumn>
      <CMBTableColumn>{currentMobileProviderDropdown()}</CMBTableColumn>
    </CMBTableRow>
  );

  const handleSave = () => {
    setCMB({
      ...savingsInfo,
      currentMonthlyBill: +currentMonthlyBill,
      currentMobileProvider
    });

    setEditMode(false);
  };

  const displayCMBSection = () => (
    <>
      <CurrentMonthlyBillFieldContainer>
        <Question>
          How much are you currently paying monthly for data?
        </Question>
        <Disclaimer>
          Include any line fees or surcharges. Subtract taxes, device
          financing payments, other fees, and insurance costs.
        </Disclaimer>
      </CurrentMonthlyBillFieldContainer>
      <CMBTable>
        <CMBHeading>
          <CMBTableRow>
            <CMBTableHeader>Current monthly bill</CMBTableHeader>
            <CMBTableHeader>Current mobile provider</CMBTableHeader>
          </CMBTableRow>
        </CMBHeading>
        <CMBBody>{savingsView()}</CMBBody>
      </CMBTable>
    </>

  );

  return (
    <CMBContainer>
      <Title
        titleText={titleText}
        editMode={editMode}
        switchToEditMode={() => setEditMode(true)}
        onSave={handleSave}
        onCancel={onCancel}
      />

      <CurrentMonthlyBillContainer>
        <CMBSubContainer>
          {displayCMBSection()}
        </CMBSubContainer>
      </CurrentMonthlyBillContainer>
    </CMBContainer>
  );
}

const CMBContainer = styled.div`
  margin: 0 0 2em 0;
`;

const CMBSubContainer = styled.div`
  padding: 1em 4em;

  @media only screen and ${deviceTypes.mobileS} {
    padding: 1em 2em;
  }

  @media only screen and ${deviceTypes.mobileM} {
    padding: 1em 2em;
  }

  @media only screen and ${deviceTypes.mobileL} {
    padding: 1em 2em;
  }

  @media only screen and ${deviceTypes.tablet} {
    padding: 1em 3em;
  }

  @media only screen and ${deviceTypes.laptop} {
    padding: 1em 4em;
  }
`;

const CurrentMonthlyBillContainer = styled.div``;

const Question = styled.div``;

const Disclaimer = styled.div`
  font-size: 0.875em;
`;

const CMBTable = styled.table`
  width: 80%;
`;

const CMBHeading = styled.thead`
  text-align: left;
`;

const CMBBody = styled.tbody``;

const CMBTableRow = styled.tr``;

const CMBTableHeader = styled.th`
  font-weight: 600;
`;

const CMBTableColumn = styled.td``;

const CurrentMonthlyBillFieldContainer = styled.div`
  padding: 0 0 1em 0;
`;

CurrentMonthlyBill.propTypes = {
  savingsInfo: PropTypes.shape({
    currentMonthlyBill: PropTypes.number,
    currentMobileProvider: PropTypes.number
  }),
  currentMobileProviderOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string
    })
  ),
  setCMB: PropTypes.func,
  editMode: PropTypes.bool,
  switchToEditMode: PropTypes.func
};

const mapStateToProps = (state) => ({
  savingsInfo: state?.savingsInfo,
  currentMobileProviderOptions: state?.fieldInfo?.currentMobileProviderDropdown,
  editMode: state?.sectionInfo?.savings?.editMode
});

const mapDispatchToProps = {
  setCMB: setCurrentMonthlyBill,
  switchToEditMode: toggleEditMode
};

export default connect(mapStateToProps, mapDispatchToProps)(CurrentMonthlyBill);
