import { SET_POPUP } from '../actions/popup';

export const INITIAL_STATE = {
  isOpen: false,
  title: '',
  content: '',
  specialContent: {},
  type: ''
};

const popUpReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_POPUP: {
      const { payload } = action;
      return {
        ...state,
        ...payload
      };
    }
    default:
      return state;
  }
};

export default popUpReducer;
