import PropTypes from 'prop-types';
import styled from 'styled-components';
import HorizontalLine from './horizontalLine';

function Title(props) {
  const {
    titleText,
    editMode,
    switchToEditMode,
    onSave,
    onCancel
  } = props;

  return (
    <TitleContainer>
      <TitleSubContainer>
        <HeaderContainer>{titleText ? titleText.toUpperCase() : 'Title Text'}</HeaderContainer>
        {
          editMode
            ? (
              <ButtonGroup>
                <LinkButton
                  type="button"
                  value="CANCEL"
                  onClick={onCancel}
                />
                <LinkButton
                  type="button"
                  value="SAVE"
                  onClick={onSave}
                />
              </ButtonGroup>
            )
            : <LinkButton type="button" value="EDIT" onClick={switchToEditMode} />
        }
      </TitleSubContainer>
      <HorizontalLine />
    </TitleContainer>
  );
}

const TitleContainer = styled.div``;

const TitleSubContainer = styled.div`
  display: flex;
  justify-content: space-between;  
  align-items: center;
  flex-direction: row;
  height: 2em;
`;

const LinkButton = styled.input`
  font-family: Montserrat;
  border: none;
  background: none;
  color: ${({ theme }) => theme.title.linkButton.color};
  cursor: pointer;
  font-weight: 700;
  &:hover {
    text-decoration: underline;
  }
  font-size: .875em;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;

const HeaderContainer = styled.h4`
  font-weight: 700;
`;

Title.propTypes = {
  titleText: PropTypes.string,
  editMode: PropTypes.bool,
  switchToEditMode: PropTypes.func,
  onSave: PropTypes.func,
  onCancel: PropTypes.func
};

export default Title;
