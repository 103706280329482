import { useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { GrClose, GrStatusDisabled } from 'react-icons/gr';
import { devices } from '../../utils/deviceType';
import QuickQuoteButton from '../utility/quickQuoteButton';
import { setPostalScrubbingPopUp } from '../../actions/postalScrubbingPopup';
import { setLoading } from '../../actions/loading';
import { submitFinalQuoteDetails } from '../../actions/quote';
import { toggleEditMode } from '../../actions/section';
import { INITIAL_STATE } from '../../reducers/postalScrubbingPopup';
import HorizontalLine from '../utility/horizontalLine';

function postalScrubbingPopup(props) {
  const {
    isOpen,
    standardAddressList,
    setupPopUp,
    finalQuoteInfo,
    showSpinner,
    submitQuoteDetails,
    switchEditMode,
  } = props;

  const [selectedScrubbedAddress, setScrubbedAddress] = useState(-1);
  const [selectedAddressDetails, setSelectedAddressDetails] = useState({});
  const [applyButtonDisable, setApplyButtonDisable] = useState(true);

  const resetState = () => {
    setScrubbedAddress(-1);
    setSelectedAddressDetails({});
    setApplyButtonDisable(true);
  };

  const onChangeRadioBtn = (id) => {
    setScrubbedAddress(id);

    standardAddressList.map((address, i) => {
      const selectedAddress = { ...address };
      if (id === i) {
        setApplyButtonDisable(false);
        return setSelectedAddressDetails({
          address1: selectedAddress.addressLine1,
          address2: selectedAddress.addressLine2,
          city: selectedAddress.city,
          state: selectedAddress.state,
          zip: selectedAddress.zipCode,
          isValidAddress: true
        });
      }

      return selectedAddress;
    });
  };

  const handleReEdit = () => {
    switchEditMode({
      contact: {
        editMode: true
      }
    });

    setupPopUp({ ...INITIAL_STATE });
    resetState();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  };

  const handleOverride = () => {
    setupPopUp({ ...INITIAL_STATE });
    showSpinner(true);
    resetState();
    submitQuoteDetails(finalQuoteInfo);
  };

  const handleApply = () => {
    setupPopUp({ ...INITIAL_STATE });
    showSpinner(true);

    // build new scrubbed contactinfo to save into the database
    const scrubbedContactInfo = { ...finalQuoteInfo.quote.contactInfo, ...selectedAddressDetails };
    const payload = { ...finalQuoteInfo,
      quote: {
        ...finalQuoteInfo.quote,
        contactInfo: scrubbedContactInfo
      }
    };
    resetState();
    submitQuoteDetails(payload);
  };

  const onResetStandardAdressHandler = () => {
    setScrubbedAddress(-1);
    setSelectedAddressDetails({});
    setApplyButtonDisable(true);
  };

  const postalScrubbingButtons = () => (
    <PostalScrubbingButtons>
      <QuickQuoteButton
        btnText="Re-edit"
        buttonAlign="right"
        onClick={handleReEdit}
        isReadonly={!applyButtonDisable}
      />
      <QuickQuoteButton
        btnText="Override"
        buttonAlign="right"
        onClick={handleOverride}
        isReadonly={!applyButtonDisable}
      />
      <QuickQuoteButton
        btnText="Apply"
        buttonAlign="right"
        onClick={handleApply}
        isReadonly={applyButtonDisable}
      />
    </PostalScrubbingButtons>
  );

  const scrubbedAddressList = () => (
    <>
      {
      standardAddressList.map((x, id) => (
        <PostalAddressRow key={id}>
          <PostalAddressElement>
            <AddressInput
              type="radio"
              value={id}
              name="address-radio"
              checked={id === selectedScrubbedAddress}
              onChange={() => onChangeRadioBtn(id)}
            />
          </PostalAddressElement>
          <PostalAddressElement>
            {x.addressLine1},<br />
            {x.addressLine2 && (() => (<>{x.addressLine2},<br /></>))()}
            {x.city}, {x.state}, {x.zipCode}
          </PostalAddressElement>
          <PostalAddressElement>
            {x.latitude}/{x.longitude}
          </PostalAddressElement>
        </PostalAddressRow>
      ))
    }
    </>
  );

  return (
    <div>
      {isOpen ? (
        <>
          <OverlayContainer />
          <PopUpContainer>
            <PopUpDialog>
              <TitleContainer>
                <Title>SCRUBBED ADDRESS</Title>
                <CloseButtonContainer>
                  <GrClose onClick={handleOverride} />
                </CloseButtonContainer>
              </TitleContainer>
              <ContentDiv>
                Select and apply a valid address from the matching results.{' '}
              </ContentDiv>
              <Content>
                <PostalAddressTable>
                  <PostalAddressTableHeader>
                    <PostalAddressHeaderRow>
                      <PostalAddressHeader>
                        <ButtonContainer>
                          <GrStatusDisabled onClick={onResetStandardAdressHandler} />
                        </ButtonContainer>
                      </PostalAddressHeader>
                      <PostalAddressHeader>Address</PostalAddressHeader>
                      <PostalAddressHeader>Latitude/Longitude</PostalAddressHeader>
                    </PostalAddressHeaderRow>
                  </PostalAddressTableHeader>
                  <PostalAddressBody>
                    {scrubbedAddressList()}
                  </PostalAddressBody>
                </PostalAddressTable>
              </Content>
              <HorizontalLine thickness="1x" type="special" />
              {postalScrubbingButtons()}
            </PopUpDialog>
          </PopUpContainer>
        </>
      ) : null}
    </div>
  );
}

const ContentDiv = styled.div`
  margin-bottom: 1em;
`;

const PostalAddressTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const PostalAddressTableHeader = styled.thead`
  text-align: left;
  background: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  position: sticky;
  top: 0;
`;

const PostalAddressBody = styled.tbody``;

const PostalAddressRow = styled.tr`
 border-bottom: 1px solid rgb(204, 204, 204);
`;

const PostalAddressHeaderRow = styled.tr`
`;

const PostalAddressHeader = styled.th`
  font-size: 1.1rem;
  padding: 10px 15px;
  border-right: 1px solid rgb(204, 204, 204);
  font-weight: 400;
`;

const PostalAddressElement = styled.td`
 padding: 10px 15px;
 border-right: 1px solid rgb(204, 204, 204);
 border-left: 1px solid rgb(204, 204, 204);
 vertical-align: top;
 font-size: 14px;
 :first-child {
   text-align: center;
 }
`;

const ButtonContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  path {
    fill: white;
  }
`;

const OverlayContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 200%;
  background-color: ${({ theme }) => theme.overlay.backgroundColor};
  opacity: 30%;
  z-index: 2;
  overflow-y: scroll;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const PostalScrubbingButtons = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-top: 1.5em;
`;

const PopUpContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
`;

const PopUpDialog = styled.div`
  border-radius: 4px;
  max-height: 70%;
  color: ${({ theme }) => theme.popup.color};
  background-color: ${({ theme }) => theme.popup.backgroundColor};
  padding: 1em;
  max-width: 55%;
  margin: auto;
  padding: 2em 3em;
  width: 40em;

  path {
    stroke: ${({ theme }) => theme.popup.color};
  }

  a {
    color: ${({ theme }) => theme.popup.link.color};
  }

  @media only screen and ${devices.laptop} {    
    max-width: ${({ type }) => (type === 'info' ? '55%' : '45%')};
  }
`;

const AddressInput = styled.input`
  width: 13px;
`;

const Title = styled.h2`
  white-space: nowrap;
  padding: 0;
  margin: 0;
  font-weight: ${({ type }) => (type !== 'info' ? '600' : '600')};
  font-size: ${({ type }) => (type !== 'info' ? '1.5em' : '1.75em')};
  line-height: 1.333333333;
  color: ${({ theme }) => theme.popup.color};

  @media only screen and ${devices.laptop} {    
    font-size: ${({ type }) => (type !== 'info' ? '1.6em' : '2em')};
  }
`;

const Content = styled.div`
  font-size: ${({ type }) => (type === 'info' ? '.95em' : '.9em')};
  height: 100%;
  max-height: 235px;
  overflow-y: auto;
  margin-bottom: 2.5em;
  border-bottom: 1px solid rgb(204, 204, 204);
`;

const CloseButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin: 0;
  cursor: pointer;
  font-size: ${({ type }) => (type !== 'info' ? '1.5em' : '1.75em')};
  color: ${({ theme }) => theme.popup.color};
  background-color: ${({ theme }) => theme.popup.backgroundColor};
`;

const TitleContainer = styled.div`
  margin: 0 0 1em 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

postalScrubbingPopup.propTypes = {
  isOpen: PropTypes.bool,
  standardAddress: PropTypes.array,
  quote: PropTypes.func
};

const mapStateToProps = (state) => ({
  isOpen: state.postalScrubbingInfo?.isOpen,
  standardAddressList: state.postalScrubbingInfo.standardAddress,
  finalQuoteInfo: state.postalScrubbingInfo?.quote
});

const mapDispatchToProps = {
  setupPopUp: setPostalScrubbingPopUp,
  showSpinner: setLoading,
  submitQuoteDetails: submitFinalQuoteDetails,
  switchEditMode: toggleEditMode
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(postalScrubbingPopup);
