import { SET_LINES_INFORMATION } from '../actions/lines';

const INITIAL_STATE = {
  unlimitedQuantity: -1,
  byTheGigQuantity: -1,
  byTheGigSharedData: -1,
  portTn: -1,
  tns: [],
  unlimitedType: -1,
  unlimitedIntroQuantity: 0,
  unlimitedPlusQuantity: 0,
  unlimitedPremiumQuantity: 0
};

const linesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_LINES_INFORMATION: {
      const { linesInfo } = action;
      return {
        ...state,
        ...linesInfo
      };
    }
    default:
      return state;
  }
};

export default linesReducer;
