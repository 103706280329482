export const GET_THEME = 'GET_THEME';
export const SET_THEME = 'SET_THEME';
export const TOGGLE_THEME = 'TOGGLE_THEME';

export const getTheme = () => ({
  type: GET_THEME
});

export const setTheme = (theme) => ({
  type: SET_THEME,
  theme
});

export const toggleTheme = () => ({
  type: TOGGLE_THEME
});
