export const GET_LOADING = 'GET_LOADING';
export const SET_LOADING = 'SET_LOADING';

export const getLoading = () => ({
  type: GET_LOADING
});

export const setLoading = (isLoading) => ({
  type: SET_LOADING,
  isLoading
});
