export const GET_LINES_INFORMATION = 'GET_LINES_INFORMATION';
export const SET_LINES_INFORMATION = 'SET_LINES_INFORMATION';

export const getLinesInformation = () => ({
  type: GET_LINES_INFORMATION
});

export const setLinesInformation = (linesInfo) => ({
  type: SET_LINES_INFORMATION,
  linesInfo
});
