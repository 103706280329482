export const SET_TOTAL_LINES = 'SET_TOTAL_LINES';
export const SET_UNIT_DROPDOWN = 'SET_UNIT_DROPDOWN';
export const SET_BY_THE_GIG_SHARED_DATA_DROPDOWN = 'SET_BY_THE_GIG_SHARED_DATA_DROPDOWN';
export const SET_CURRENT_MOBILE_PROVIDER_DROPDOWN = 'SET_CURRENT_MOBILE_PROVIDER_DROPDOWN';
export const SET_PCAT_DEVICE_DROPDOWN = 'SET_PCAT_DEVICE_DROPDOWN';
export const SET_PCAT_DEVICE_INFO = 'SET_PCAT_DEVICE_INFO';
export const SET_UNLIMITED_TYPE_DROPDOWN = 'SET_UNLIMITED_TYPE_DROPDOWN';
export const SET_UNLIMITED_DROPDOWN = 'SET_UNLIMITED_DROPDOWN';
export const SET_UNLIMITED_INTRO_DROPDOWN = 'SET_UNLIMITED_INTRO_DROPDOWN';
export const SET_CUSTOMER_NAME = 'SET_CUSTOMER_NAME';

export const setTotalLines = (totalLines) => ({
  type: SET_TOTAL_LINES,
  totalLines
});

export const setUnitDropdown = (unitsDropdown) => ({
  type: SET_UNIT_DROPDOWN,
  unitsDropdown
});

export const setByTheGigSharedDataDropdown = (byTheGigSharedDataDropdown) => ({
  type: SET_BY_THE_GIG_SHARED_DATA_DROPDOWN,
  byTheGigSharedDataDropdown
});

export const setUnlimitedTypeDropdown = (unlimitedTypeDropdown) => ({
  type: SET_UNLIMITED_TYPE_DROPDOWN,
  unlimitedTypeDropdown
});

export const setUnlimitedDropdown = (unlimitedDropdown) => ({
  type: SET_UNLIMITED_DROPDOWN,
  unlimitedDropdown
});

export const setUnlimitedIntroDropdown = (unlimitedIntroDropdown) => ({
  type: SET_UNLIMITED_INTRO_DROPDOWN,
  unlimitedIntroDropdown
});

export const setCurrentMobileProviderDropdown = (currentMobileProviderDropdown) => ({
  type: SET_CURRENT_MOBILE_PROVIDER_DROPDOWN,
  currentMobileProviderDropdown
});

export const setPcatDeviceDataDropdown = (pcatDeviceDropdown) => ({
  type: SET_PCAT_DEVICE_DROPDOWN,
  pcatDeviceDropdown
});

export const setPcatDeviceInfo = (pcatDeviceInfo) => ({
  type: SET_PCAT_DEVICE_INFO,
  pcatDeviceInfo
});

export const setCustomerName = (customerName) => ({
  type: SET_CUSTOMER_NAME,
  customerName
});
