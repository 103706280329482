import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Title from '../utility/title';
import PortTn from './portTn';
import HorizontalLine from '../utility/horizontalLine';
import { setPopUp } from '../../actions/popup';
import { PricingInfoContent } from '../../utils/staticContent';
import Dropdown from '../utility/dropdown';
import { selectLinesInfo } from '../../selectors/quote';
import { setLinesInformation } from '../../actions/lines';
import { generateNewTnsValidationList } from '../utility/lines/lines';
import { devices } from '../../utils/deviceType';
import { UNLIMITED_EXISTING_TYPE, UNLIMITED_INTRO_TYPE } from '../../utils/constants';
import { toggleEditMode } from '../../actions/section';

const INITIAL_VALIDATION_STATE = {
  byTheGigQuantity: {
    isValid: true,
    errorMessage: ''
  },
  byTheGigSharedData: {
    isValid: true,
    errorMessage: ''
  },
  tns: [],
  unlimitedQuantity: {
    isValid: true,
    errorMessage: ''
  },
  unlimitedIntroQuantity: {
    isValid: true,
    errorMessage: ''
  }
};

function Lines(props) {
  const [validationList, setValidationList] = useState(INITIAL_VALIDATION_STATE);

  const {
    linesInfo,
    setupPopUp,
    unitsDropdown,
    byTheGigSharedDataDropdown,
    setLinesInfo,
    totalLines,
    unlimitedTypeDropdown,
    unlimitedDropdown,
    unlimitedIntroDropdown,
    editMode,
    switchEditMode,
  } = props;

  const [{
    unlimitedQuantity,
    byTheGigQuantity,
    byTheGigSharedData,
    portTn,
    tns,
    unlimitedType,
    unlimitedIntroQuantity,
    unlimitedPlusQuantity,
    unlimitedPremiumQuantity
  }, setState] = useState(linesInfo);

  const setEditMode = (editState) => (
    switchEditMode({
      lines: {
        editMode: editState
      }
    })
  );

  const portingInfo = {
    portTn,
    tns
  };

  useEffect(() => {
    if (linesInfo) {
      setState(linesInfo);

      // set validation state
      const tnsInitialValidationList = generateNewTnsValidationList(linesInfo.tns);

      const updatedValidationList = { ...validationList };
      updatedValidationList.tns = tnsInitialValidationList;

      if (linesInfo.tns !== undefined) {
        // set initial tn validation state
        if (linesInfo.tns.length > 0) {
          if (linesInfo.tns.filter((x) => !x.isValid).length > 0) {
            const totalInfo = linesInfo.tns.map((x) => {
              if (!x.isValid) {
                return {
                  ...x,
                  tn: {
                    isValid: false,
                    errorMessage: 'Please enter a valid phone number.'
                  }
                };
              }
              return x;
            });

            updatedValidationList.tns = totalInfo;
          }
        }
      }
      setValidationList(updatedValidationList);
    }
  }, [linesInfo, setState, editMode]);

  // The below code should be moved to store
  const titleText = 'Lines and Data Options';

  const ToggleValidationMessageIfEligible = (fieldValue, fieldName) => {
    if (fieldValue !== '' && validationList[fieldName] !== null) {
      setValidationList({
        ...validationList,
        [fieldName]: {
          ...INITIAL_VALIDATION_STATE[fieldName]
        }
      });
    }
  };

  const handleDropdownValueChange = (e) => {
    const fieldName = e.target.name;
    const fieldValue = +e.target.value;

    // Turn off any active validation message that is associated to the
    // field if the changed value is non-empty
    ToggleValidationMessageIfEligible(fieldValue, fieldName);

    // Reset unlimited & unlimited intro previous selection whenever unlimitedType value changes
    if (fieldName === 'unlimitedType') {
      setState((state) => ({
        ...state,
        [fieldName]: fieldValue,
        unlimitedQuantity: 0,
        unlimitedIntroQuantity: 0,
        unlimitedPlusQuantity: 0,
        unlimitedPremiumQuantity: 0
      }));
    } else if (fieldName === 'unlimitedIntroQuantity' && unlimitedIntroQuantity === 0) {
      setState((state) => ({
        ...state,
        [fieldName]: fieldValue,
        unlimitedPlusQuantity: 0,
        unlimitedPremiumQuantity: 0
      }));
    } else {
      setState((state) => ({
        ...state,
        [fieldName]: fieldValue
      }));
    }
  };

  const showValidationAlert = (content) => {
    const popUpProps = {
      isOpen: true,
      type: 'error',
      title: 'ERROR',
      content
    };
    setupPopUp(popUpProps);
  };

  const validateLinesInfo = () => {
    let isValid = true;
    const tnsInitialValidationList = generateNewTnsValidationList(tns);
    const updatedValidationList = {
      ...INITIAL_VALIDATION_STATE,
      tns: [...tnsInitialValidationList]
    };

    if (byTheGigQuantity > 0 && byTheGigSharedData === -1) {
      const updatedByTheGigSharedDataItem = { ...updatedValidationList.byTheGigSharedData };
      updatedByTheGigSharedDataItem.isValid = false;
      updatedByTheGigSharedDataItem.errorMessage = 'Choose By the Gig Data.';
      updatedValidationList.byTheGigSharedData = updatedByTheGigSharedDataItem;
      isValid = false;
    }

    if (byTheGigQuantity === 0 && byTheGigSharedData > -1) {
      const updatedByTheGigQuantityItem = { ...updatedValidationList.byTheGigQuantity };
      updatedByTheGigQuantityItem.isValid = false;
      updatedByTheGigQuantityItem.errorMessage = 'Please make a selection.';
      updatedValidationList.byTheGigQuantity = updatedByTheGigQuantityItem;
      isValid = false;
    }

    if (unlimitedType === UNLIMITED_INTRO_TYPE && unlimitedIntroQuantity === 0) {
      const updatedUnlimitedIntroItem = { ...updatedValidationList.unlimitedIntroQuantity };
      updatedUnlimitedIntroItem.isValid = false;
      updatedUnlimitedIntroItem.errorMessage = 'Please make a selection.';
      updatedValidationList.unlimitedIntroQuantity = updatedUnlimitedIntroItem;
      isValid = false;
    }

    if (unlimitedType === UNLIMITED_EXISTING_TYPE && unlimitedQuantity === 0) {
      const updatedUnlimitedItem = { ...updatedValidationList.unlimitedLines };
      updatedUnlimitedItem.isValid = false;
      updatedUnlimitedItem.errorMessage = 'Please make a selection.';
      updatedValidationList.unlimitedQuantity = updatedUnlimitedItem;
      isValid = false;
    }

    if (tns.length > 0) {
      const updatedTnsValidationList = [...updatedValidationList.tns];

      tns.map((x, i) => {
        if (x.tn?.value === null || x.tn?.value === '' || x.tn?.value === undefined) {
          const validationItemToBeUpdated = updatedTnsValidationList[i];
          const updatedTnItem = { ...validationItemToBeUpdated.tn };
          updatedTnItem.isValid = false;
          updatedTnItem.errorMessage = 'Please enter a valid phone number.';
          validationItemToBeUpdated.tn = updatedTnItem;
          isValid = false;
        } else if (x.tn.value.length !== 10) {
          const validationItemToBeUpdated = updatedTnsValidationList[i];
          const updatedTnItem = { ...validationItemToBeUpdated.tn };
          updatedTnItem.isValid = false;
          updatedTnItem.errorMessage = 'Please enter a valid phone number.'; // Please enter a valid 10 digit phone number.
          validationItemToBeUpdated.tn = updatedTnItem;
          isValid = false;
        }

        if (x.cmp === -1) {
          const validationItemToBeUpdated = updatedTnsValidationList[i];
          const updatedCmpItem = { ...validationItemToBeUpdated.cmp };
          updatedCmpItem.isValid = false;
          updatedCmpItem.errorMessage = 'Please select a provider.';
          validationItemToBeUpdated.cmp = updatedCmpItem;
          isValid = false;
        }
        return x;
      });

      updatedValidationList.tns = updatedTnsValidationList;
    }

    if (!isValid) {
      return { isValid, updatedValidationList };
    }

    let totalUnlimitedQuantity = unlimitedQuantity;
    let unlimitedTypeText = 'Unlimited';
    if (unlimitedType === UNLIMITED_INTRO_TYPE) {
      totalUnlimitedQuantity = unlimitedIntroQuantity;
      unlimitedTypeText = 'Unlimited Intro';
    }

    if ((totalUnlimitedQuantity + byTheGigQuantity) > totalLines) {
      showValidationAlert(`The quantity selected for By the Gig and ${unlimitedTypeText} exceeds the maximum of ${totalLines} lines. Please ensure the total number of lines for By the Gig and ${unlimitedTypeText} is equal to or less than ${totalLines}.`);
      isValid = false;
      return { isValid };
    }

    if (unlimitedIntroQuantity < (unlimitedPlusQuantity + unlimitedPremiumQuantity)) {
      showValidationAlert('The quantity selected for Unlimited Plus and Unlimited Premium exceeds the number of Unlimited Intro lines. Please update the Unlimited Plus and Unlimited Premium lines to not exceed the total number of Unlimited Intro lines.');
      isValid = false;
      return { isValid };
    }

    if (portTn > (totalUnlimitedQuantity + byTheGigQuantity)) {
      showValidationAlert('The quantity selected for Porting Phone Number(s) must be equal to or less than the total number of Lines (Unlimited or Unlimited Intro + By the Gig). Please modify and validate.');
      isValid = false;
      return { isValid };
    }

    return { isValid };
  };

  const handleSave = () => {
    const validationResult = validateLinesInfo();
    if (!validationResult.isValid) {
      if (validationResult?.updatedValidationList !== undefined) {
        setValidationList(validationResult.updatedValidationList);
      }
      return;
    }

    setLinesInfo({
      byTheGigQuantity,
      byTheGigSharedData,
      portTn,
      tns,
      unlimitedType,
      unlimitedQuantity,
      unlimitedIntroQuantity,
      unlimitedPlusQuantity,
      unlimitedPremiumQuantity
    });

    const tnsInitialValidationList = generateNewTnsValidationList(linesInfo.tns);
    const updatedValidationList = { ...INITIAL_VALIDATION_STATE };
    updatedValidationList.tns = tnsInitialValidationList;

    setValidationList(updatedValidationList);
    setEditMode(false);
  };

  const handleCancel = () => {
    setEditMode(false);
    const tnsInitialValidationList = generateNewTnsValidationList(linesInfo.tns);
    const updatedValidationList = { ...INITIAL_VALIDATION_STATE };
    updatedValidationList.tns = tnsInitialValidationList;

    setValidationList(updatedValidationList);
    setState(linesInfo);
  };

  const showPricingLink = () => {
    const showPricingPopUp = () => {
      const popUpProps = {
        isOpen: true,
        type: 'info',
        title: PricingInfoContent.title,
        specialContent: PricingInfoContent.content
      };
      setupPopUp(popUpProps);
    };

    return (
      <LinkButton type="button" value="Pricing & Other Info" onClick={showPricingPopUp} />
    );
  };

  const renderLinesSection = () => (
    <LinesReadOnlyContainer>
      <LinesContainerHeader>
        Select the number of mobile lines you need for each data option:
      </LinesContainerHeader>
      <ByTheGigContainer>
        <DataLinesContainer>
          <DataLinesFieldContainer>
            <QuestionContainer>
              How many By the Gig - Shared Data lines?
            </QuestionContainer>
          </DataLinesFieldContainer>
          <DataLinesSelectionContainer>
            <Dropdown
              name="byTheGigQuantity"
              selectedValue={byTheGigQuantity}
              options={unitsDropdown}
              onChange={handleDropdownValueChange}
              disabled={!editMode}
              isValid={validationList.byTheGigQuantity.isValid}
              errorMessage={validationList.byTheGigQuantity.errorMessage}
            />
          </DataLinesSelectionContainer>
        </DataLinesContainer>
        <SharedDataContainer>
          <SharedDataFieldContainer>
            <QuestionContainer>How much By the Gig - Shared Data do you need?</QuestionContainer>
            <DescriptionContainer>
              Choose a minimum amount of shared data, with the freedom to adjust it if your
              business needs change. Just right for teams in locations where there is more
              WiFi and less cellular use - like offices and retail stores.<br />
              {showPricingLink()}
            </DescriptionContainer>
          </SharedDataFieldContainer>
          <SharedDataSelectionContainer isValid={validationList.byTheGigSharedData.isValid}>
            <Dropdown
              name="byTheGigSharedData"
              selectedValue={byTheGigSharedData}
              options={byTheGigSharedDataDropdown}
              onChange={handleDropdownValueChange}
              disabled={!editMode}
              isValid={validationList.byTheGigSharedData.isValid}
              errorMessage={validationList.byTheGigSharedData.errorMessage}
            />
          </SharedDataSelectionContainer>
        </SharedDataContainer>
        <UnlimitedLinesContainer>
          <UnlimitedFieldContainer>
            <QuestionContainer>
              Which Unlimited Plan are you interested in?
            </QuestionContainer>
            <DescriptionContainer>
              Select &quot;Unlimited&quot; for existing customers continuing with
              unlimited services OR &quot;Unlimited Intro&quot; for new customers and
              existing customers switching over to the updated plan. <br />
              Use as much data as you want. The more Unlimited
              lines on your account, up to {totalLines},
              the lower your monthly cost per line.
              Great for on-the-go employees who may not be in WiFi range and need to use
              cellular data.<br />
              {showPricingLink()}
            </DescriptionContainer>
          </UnlimitedFieldContainer>
          <UnlimitedSelectionContainer>
            <Dropdown
              name="unlimitedType"
              selectedValue={unlimitedType}
              options={unlimitedTypeDropdown}
              onChange={handleDropdownValueChange}
              disabled={!editMode}
              leftAlign
            />
          </UnlimitedSelectionContainer>
        </UnlimitedLinesContainer>
        { unlimitedType === UNLIMITED_EXISTING_TYPE
          ? (
            <DataLinesContainer>
              <DataLinesFieldContainer>
                <QuestionContainer>
                  How many Unlimited lines? (for existing customers only)
                </QuestionContainer>
              </DataLinesFieldContainer>
              <DataLinesSelectionContainer isValid={validationList.unlimitedQuantity.isValid}>
                <Dropdown
                  name="unlimitedQuantity"
                  selectedValue={unlimitedQuantity}
                  options={unlimitedDropdown}
                  onChange={handleDropdownValueChange}
                  disabled={!editMode}
                  isValid={validationList.unlimitedQuantity.isValid}
                  errorMessage={validationList.unlimitedQuantity.errorMessage}
                />
              </DataLinesSelectionContainer>
            </DataLinesContainer>
          ) : unlimitedType === UNLIMITED_INTRO_TYPE
            ? (
              <>
                <DataLinesContainer>
                  <DataLinesFieldContainer>
                    <QuestionContainer>
                      How many Unlimited Intro lines?
                    </QuestionContainer>
                  </DataLinesFieldContainer>
                  <DataLinesSelectionContainer
                    isValid={validationList.unlimitedIntroQuantity.isValid}
                  >
                    <Dropdown
                      name="unlimitedIntroQuantity"
                      selectedValue={unlimitedIntroQuantity}
                      options={unlimitedIntroDropdown}
                      onChange={handleDropdownValueChange}
                      disabled={!editMode}
                      isValid={validationList.unlimitedIntroQuantity.isValid}
                      errorMessage={validationList.unlimitedIntroQuantity.errorMessage}
                    />
                  </DataLinesSelectionContainer>
                </DataLinesContainer>
                {unlimitedIntroQuantity > 0
                  ? (
                    <>
                      <DataLinesContainer>
                        <DataLinesFieldContainer>
                          <QuestionContainer>
                            Would you like to upgrade the Unlimited Intro lines to Unlimited Plus?
                          </QuestionContainer>
                        </DataLinesFieldContainer>
                        <DataLinesSelectionContainer>
                          <Dropdown
                            name="unlimitedPlusQuantity"
                            selectedValue={unlimitedPlusQuantity}
                            options={unitsDropdown}
                            onChange={handleDropdownValueChange}
                            disabled={!editMode}
                          />
                        </DataLinesSelectionContainer>
                      </DataLinesContainer>
                      <DataLinesContainer>
                        <DataLinesFieldContainer>
                          <QuestionContainer>
                            Would you like to upgrade the Unlimited Intro lines to Unlimited
                            Premium?
                          </QuestionContainer>
                        </DataLinesFieldContainer>
                        <DataLinesSelectionContainer>
                          <Dropdown
                            name="unlimitedPremiumQuantity"
                            selectedValue={unlimitedPremiumQuantity}
                            options={unitsDropdown}
                            onChange={handleDropdownValueChange}
                            disabled={!editMode}
                          />
                        </DataLinesSelectionContainer>
                      </DataLinesContainer>
                    </>
                  )
                  : null }
              </>
            ) : null }
        <HorizontalLine />
        <PortTn
          editMode={editMode}
          portingInfo={portingInfo}
          setState={setState}
          validationList={validationList}
          setValidationList={setValidationList}
        />
      </ByTheGigContainer>

    </LinesReadOnlyContainer>
  );

  return (
    <LinesContainer>
      <Title
        titleText={titleText}
        editMode={editMode}
        switchToEditMode={() => setEditMode(true)}
        onSave={handleSave}
        onCancel={handleCancel}
      />
      <LinesSubContainer>
        {renderLinesSection()}
      </LinesSubContainer>
    </LinesContainer>
  );
}

const LinesContainer = styled.div`
  margin: 0 0 2em 0;
`;

const LinesContainerHeader = styled.p``;

const LinesSubContainer = styled.div`
  padding: 1em 4em;

  @media only screen and ${devices.mobileS} {
    padding: 1em 2em;
  }
    
  @media only screen and ${devices.mobileM} {
    padding: 1em 2em;
  }
    
  @media only screen and ${devices.mobileL} {
    padding: 1em 2em;
  }
    
  @media only screen and ${devices.tablet} {
    padding: 1em 3em;
  }
    
  @media only screen and ${devices.laptop} {
    padding: 1em 4em;
  }
`;

const LinesReadOnlyContainer = styled.div``;

const UnlimitedLinesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin: 1.5em 0 1.5em 0;  
`;

const UnlimitedFieldContainer = styled.div``;

const UnlimitedSelectionContainer = styled.div`
`;

const QuestionContainer = styled.p`
  margin: 0;
`;

const DescriptionContainer = styled.p`
  font-size: 0.8750em;
  margin: 0;
`;

const LinkButton = styled.input`
  font-size: .875em;
  border: none;
  background: none;
  padding: 0;
  color: ${({ theme }) => theme.title.linkButton.color};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const ByTheGigContainer = styled.div``;

const DataLinesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 0 1em 0;
`;

const DataLinesFieldContainer = styled.div``;

const DataLinesSelectionContainer = styled.div`
  margin: 0 0 0 ${({ isValid }) => (!isValid ? '2em' : '3.5em')};
`;

const SharedDataContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;  
  margin: 0 0 2em 0;
`;

const SharedDataFieldContainer = styled.div``;

const SharedDataSelectionContainer = styled.div`
  margin: 0 0 0 ${({ isValid }) => (!isValid ? '2em' : '3.5em')};
`;

Lines.propTypes = {
  linesInfo: PropTypes.shape({
    unlimitedQuantity: PropTypes.number,
    byTheGigQuantity: PropTypes.number,
    byTheGigSharedData: PropTypes.number,
    portTn: PropTypes.number,
    tns: PropTypes.array,
    unlimitedType: PropTypes.number,
    unlimitedIntroQuantity: PropTypes.number,
    unlimitedPlusQuantity: PropTypes.number,
    unlimitedPremiumQuantity: PropTypes.number
  }),
  setupPopUp: PropTypes.func,
  unitsDropdown: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  })),
  byTheGigSharedDataDropdown: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  })),
  setLinesInfo: PropTypes.func,
  totalLines: PropTypes.number,
  unlimitedTypeDropdown: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  })),
  unlimitedDropdown: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  })),
  unlimitedIntroDropdown: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  })),
  editMode: PropTypes.bool,
  switchEditMode: PropTypes.func
};

const mapStateToProps = (state) => ({
  linesInfo: selectLinesInfo(state),
  unitsDropdown: state.fieldInfo?.unitsDropdown,
  byTheGigSharedDataDropdown: state.fieldInfo?.byTheGigSharedDataDropdown,
  unlimitedTypeDropdown: state.fieldInfo?.unlimitedTypeDropdown,
  unlimitedDropdown: state.fieldInfo?.unlimitedDropdown,
  unlimitedIntroDropdown: state.fieldInfo?.unlimitedIntroDropdown,
  totalLines: state.quoteInfo?.quote?.totalLines,
  editMode: state.sectionInfo?.lines?.editMode,
});

const mapDispatchToProps = {
  setupPopUp: setPopUp,
  setLinesInfo: setLinesInformation,
  switchEditMode: toggleEditMode
};

export default connect(mapStateToProps, mapDispatchToProps)(Lines);
