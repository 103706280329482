import { Provider } from 'react-redux';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Quote from './components/quote';
import GlobalStyle from './globalStyle';
import ExpiredPage from './components/pages/expiredPage';
import store from './store';
import ThemeContainer from './utils/themeContainer';

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <ThemeContainer>
          <GlobalStyle />
          <Routes>
            <Route
              exact
              path=""
              element={(
                <Quote />
              )}
            />
            <Route path="/expired" element={<ExpiredPage />} />
          </Routes>
        </ThemeContainer>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
