import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import HorizontalLine from './horizontalLine';

function InputField(props) {
  const {
    id,
    name,
    type,
    isRequired,
    onChange,
    onBlur,
    onFocus,
    value,
    labelName,
    isValid,
    errorMessage,
    maxLength,
    inputFieldStyle,
    disabled,
    inputType,
    placeholder
  } = props;

  const displaySpecialInputField = () => (
    <>
      <SpecialFormFieldContainer>
        <FormField isValid={isValid}>
          <FormLabel htmlFor={name}>
            {labelName} {isRequired ? <RequiredMarker>*</RequiredMarker> : null}
          </FormLabel>
          <SpecialFormInput
            type={type}
            name={name}
            id={id}
            required={isRequired}
            onChange={onChange}
            value={!value ? '' : value}
            maxLength={maxLength}
            disabled={disabled}
          />
        </FormField>
        {/* {
          !isValid
            ? (<ValidationIndicator>!</ValidationIndicator>)
            : null
        } */}
      </SpecialFormFieldContainer>
      <HorizontalLine thickness="1x" />
      {!isValid ? (
        <ValidationMessageContainer>{errorMessage}</ValidationMessageContainer>
      ) : null}
    </>
  );

  const displayNormalInputField = () => (
    <>
      <NormalFormFieldContainer>
        <NormalFormInput
          type={type}
          name={name}
          id={id}
          required={isRequired}
          onChange={onChange}
          value={!value ? '' : value}
          maxLength={maxLength}
          disabled={disabled}
          isValid={isValid}
          inputType={inputType}
          placeholder={placeholder}
        />
        {/* {
          !isValid
            ? (<ValidationIndicator>!</ValidationIndicator>)
            : null
        } */}
      </NormalFormFieldContainer>
      {!isValid ? (
        <ValidationMessageContainer>{errorMessage}</ValidationMessageContainer>
      ) : null}
    </>
  );

  const displayCurrencyInputField = () => (
    <CurrencyFieldDisplay disabled={disabled}>
      <CurrencySpan disabled={disabled}>$</CurrencySpan>
      <CurrencyFormInput
        type={type}
        name={name}
        id={id}
        required={isRequired}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        value={value}
        maxLength={maxLength}
        disabled={disabled}
      />
    </CurrencyFieldDisplay>
  );

  if (inputFieldStyle === 'special') {
    return displaySpecialInputField();
  }
  if (inputFieldStyle === 'currency') {
    return displayCurrencyInputField();
  }
  return displayNormalInputField();
}

InputField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  inputType: PropTypes.string,
  isRequired: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string,
  labelName: PropTypes.string,
  isValid: PropTypes.bool,
  errorMessage: PropTypes.string,
  maxLength: PropTypes.number,
  inputFieldStyle: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string
};

InputField.defaultProps = {
  id: '',
  name: '',
  type: '',
  inputType: '',
  isRequired: false,
  onChange: null,
  value: '',
  labelName: '',
  isValid: true,
  errorMessage: '',
  maxLength: 0,
  inputFieldStyle: 'normal',
  disabled: false,
  placeholder: ''
};

const FormFieldContainerCommon = css`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const CurrencySpan = styled.span`
  text-align: center;
  margin-left: 7px;
  background-color: ${({ theme, disabled }) => (disabled
    ? theme.inputField.normal.disabled.backgroundColor
    : theme.inputField.normal.backgroundColor)};
`;

const SpecialFormFieldContainer = styled.div`
  ${FormFieldContainerCommon}
`;

const NormalFormFieldContainer = styled.div`
  ${FormFieldContainerCommon}
`;

const FormField = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.inputField.color};
  background-color: ${({ theme }) => theme.inputField.backgroundColor};
  justify-content: center;
  align-items: flex-start;
  width: 50%;
  margin: 0.5em 0;
  padding: 0 0.3em;
  border: ${({ isValid }) => (!isValid ? '1.75px solid red' : '')};
`;

const SpecialFormInput = styled.input`
  border: none;
  color: ${({ theme }) => theme.inputField.color};
  background: transparent;
  font-size: 1em;
  width: 90%;
  height: 100%;

  &:focus {
    outline: none;
  }
`;

const NormalFormInput = styled.input`
  font-family: Roboto;
  color: ${({ theme, disabled }) => (disabled
    ? theme.inputField.normal.disabled.color
    : theme.inputField.normal.color)};
  padding: 0.5em;
  font-size: 1em;
  width: 90%;
  height: 100%;
  background-color: ${({ theme, disabled }) => (disabled
    ? theme.inputField.normal.disabled.backgroundColor
    : theme.inputField.normal.backgroundColor)};
  border: ${({ disabled, theme }) => (disabled ? 'none' : `2px solid ${theme.inputField.normal.color}`)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  border: ${({ isValid }) => (!isValid ? '1px solid red' : '')};
  min-width: 10em;

  &:focus {
    outline: none;
  }
`;

const CurrencyFormInput = styled.input`
  font-family: Roboto;
  color: ${({ theme, disabled }) => (disabled
    ? theme.inputField.normal.disabled.color
    : theme.inputField.normal.color)};
  padding: 0.5em;
  font-size: 1em;
  width: 90%;
  height: 100%;
  background-color: ${({ theme, disabled }) => (disabled
    ? theme.inputField.normal.disabled.backgroundColor
    : theme.inputField.normal.backgroundColor)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  border: none;

  &:focus {
    outline: none;
  }
`;

const CurrencyFieldDisplay = styled.div`
  border: 2px solid black;
  width: 70%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme, disabled }) => (disabled
    ? theme.inputField.normal.disabled.backgroundColor
    : theme.inputField.normal.backgroundColor)};
  border: ${({ disabled, theme }) => (disabled ? 'none' : `2px solid ${theme.inputField.normal.color}`)};
`;

const FormLabel = styled.label`
  font-size: 0.85em;
`;

const RequiredMarker = styled.span`
  color: red;
`;

const ValidationMessageContainer = styled.div`
  color: red;
  font-size: 0.85em;
`;

// const ValidationIndicator = styled.div`
//   text-align: center;
//   width: 1.5em;
//   height: 1.5em;
//   border-radius: .15em;
//   margin: .5em .5em;
//   color: white;
//   background-color: red;
// `;

export default InputField;
