export const GET_SESSION = 'GET_SESSION';
export const SET_SESSION = 'SET_SESSION';

export const getSession = () => ({
  type: GET_SESSION
});

export const setSession = (session) => ({
  type: SET_SESSION,
  session
});
