import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { selectCurrentTheme } from '../selectors/theme';
import theme from '../theme';

function ThemeContainer(props) {
  const {
    currentTheme,
    children
  } = props;

  return (
    <ThemeProvider theme={theme[currentTheme]}>
      { children }
    </ThemeProvider>
  );
}

ThemeContainer.propTypes = {
  currentTheme: PropTypes.string,
  children: PropTypes.array
};

const mapStateToProps = (state) => ({
  currentTheme: selectCurrentTheme(state)
});

export default connect(mapStateToProps)(ThemeContainer);
