export const ValidateNoSelections = (totalLines, totalDevices) => (
  totalLines === 0 && totalDevices === 0
);

export const ValidateLinesEqualToDevices = (totalLines, totalDevices) => (
  totalLines === totalDevices
);

export const ValidateTnNumbers = (tns) => {
  const tnValidationResult = tns.map((teleNum) => {
    const newteleNum = { ...teleNum };

    const tollFreeCodeList = ['800', '888', '877', '866', '855', '844', '833', '000'];

    if (tollFreeCodeList.findIndex((x) => x === newteleNum.tn?.value?.slice(0, 3)) === -1) {
      newteleNum.isValid = true;
    } else {
      newteleNum.isValid = false;
    }

    return newteleNum;
  });

  return tnValidationResult;
};
