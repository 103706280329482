import { SyncLoader } from 'react-spinners';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled, { ThemeContext } from 'styled-components';
import { useContext } from 'react';

function Spinner(props) {
  const theme = useContext(ThemeContext);

  const {
    isLoading
  } = props;

  return (
    <div>
      {
        isLoading
          ? (
            <>
              <OverlayContainer />
              <SpinnerContainer>
                <SyncLoader color={theme.spinner.color || 'white'} size={30} />
              </SpinnerContainer>
            </>
          ) : null
      }
    </div>
  );
}

const OverlayContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 200%;
  background: black;
  opacity: 50%;
  z-index: 2;
`;

const SpinnerContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;  
  display: flex;
  justify-content: center;  
  align-items: center;
  z-index: 3;
`;

Spinner.propTypes = {
  isLoading: PropTypes.bool
};

const mapStateToProps = (state) => ({
  isLoading: state.loading?.isLoading
});

export default connect(mapStateToProps)(Spinner);
