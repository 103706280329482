import { SET_DEVICES_INFORMATION } from '../actions/devices';

const INITIAL_STATE = {
  devices: [],
  byod: 0,
  imei: []
};

const devicesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_DEVICES_INFORMATION: {
      const { devicesInformation } = action;
      return {
        ...state,
        ...devicesInformation
      };
    }
    default:
      return state;
  }
};

export default devicesReducer;
