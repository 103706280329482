export const GET_QUOTE = 'GET_QUOTE';
export const SET_QUOTE = 'SET_QUOTE';
export const SUBMIT_QUOTE = 'SUBMIT_QUOTE';
export const SUBMIT_FINAL_QUOTE = 'SUBMIT_FINAL_QUOTE';

export const getQuote = (encryptedNotificationId) => ({
  type: GET_QUOTE,
  encryptedNotificationId
});

export const setQuote = (quote) => ({
  type: SET_QUOTE,
  quote
});

export const submitQuote = (quote) => ({
  type: SUBMIT_QUOTE,
  quote
});

export const submitFinalQuoteDetails = ({ quote }) => ({
  type: SUBMIT_FINAL_QUOTE,
  quote
});
