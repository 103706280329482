import { applyMiddleware, combineReducers, createStore, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import loadingReducer from './reducers/loading';
import quoteReducer from './reducers/quote';
import themeReducer from './reducers/theme';
import userReducer from './reducers/user';
import sessionReducer from './reducers/session';
import watcherSaga from './sagas';
import contactReducer from './reducers/contact';
import popUpReducer from './reducers/popup';
import fieldReducer from './reducers/field';
import linesReducer from './reducers/lines';
import devicesReducer from './reducers/devices';
import savingsReducer from './reducers/savings';
import sectionReducer from './reducers/section';
import postalScrubbingPopUpReducer from './reducers/postalScrubbingPopup';

const reducers = combineReducers({
  user: userReducer,
  quoteInfo: quoteReducer,
  contactInfo: contactReducer,
  linesInfo: linesReducer,
  devicesInfo: devicesReducer,
  theme: themeReducer,
  loading: loadingReducer,
  sessionInfo: sessionReducer,
  popUp: popUpReducer,
  fieldInfo: fieldReducer,
  savingsInfo: savingsReducer,
  sectionInfo: sectionReducer,
  postalScrubbingInfo: postalScrubbingPopUpReducer,
});

const sagaMiddleWare = createSagaMiddleware();

const middlewares = [sagaMiddleWare];

let composeEnhancer = compose;

if (process.env.NODE_ENV === 'development') {
  composeEnhancer = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
}

const store = createStore(
  reducers,
  composeEnhancer(applyMiddleware(...middlewares))
);

sagaMiddleWare.run(watcherSaga);

export default store;
