import styled from 'styled-components';
import { ReactComponent as LinkExpired } from './link-expired.svg';

function ExpiredPage() {
  return (
    <ExpiredPageContainer>
      <ExpiredMessageContainer>
        <ImageContainer>
          <LinkExpired width="100%" height="100%" />
        </ImageContainer>
        <Title>Sorry</Title>
        <Content>
          <SectionContainer>
            <div>The page you are trying to access is no longer available.</div>
            <div>
              Click here
              (
              <a target="_blank" rel="noopener noreferrer" href="https://business.comcast.com/learn/mobile">
                https://business.comcast.com/learn/mobile
              </a>
              )
              to be redirected to the Comcast Business Mobile webpage.
            </div>
          </SectionContainer>
        </Content>
      </ExpiredMessageContainer>
    </ExpiredPageContainer>
  );
}

const ExpiredPageContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;  
  justify-content: center;
  align-items: center;
  background: url('/pattern.png');
`;

const ExpiredMessageContainer = styled.div`
  text-align: center;
  width: 30%;
  padding: 2em;
  border-radius: .5em;
  background-color: white;
  -webkit-box-shadow: 0px 0px 82px -6px rgba(169,169,169,1);
  -moz-box-shadow: 0px 0px 82px -6px rgba(169,169,169,1);
  box-shadow: 0px 0px 82px -6px rgba(169,169,169,1);
  word
`;

const Title = styled.span`
  font-size: 4em;
  color: #36454F;
  font-weight: 600;
`;

const Content = styled.div`
  word-break: break-word;
`;

const ImageContainer = styled.div`
  width: 25%;
  height: 25%;
  margin: auto;
`;

const SectionContainer = styled.section`
  margin-top: .5em;
`;

export default ExpiredPage;
