import { SET_CURRENT_MONTHLY_BILL } from '../actions/savings';

const INITIAL_STATE = {
  currentMonthlyBill: 0,
  currentMobileProvider: -1
};

const savingsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CURRENT_MONTHLY_BILL: {
      const { currentMonthlyBill } = action;
      return {
        ...state,
        ...currentMonthlyBill
      };
    }
    default:
      return state;
  }
};

export default savingsReducer;
