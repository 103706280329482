import styled from 'styled-components';
import PropTypes from 'prop-types';

function HorizontalLine(props) {
  const {
    thickness,
    type
  } = props;

  return (<SectionDivider thickness={thickness} type={type} />);
}

const SectionDivider = styled.hr`
  height: ${({ thickness }) => (thickness === '1x' ? '2px' : '3px')};
  background-color: ${({ theme }) => theme.title.horizontalLine.backgroundColor};
  border: none;
  margin: ${({ type }) => (type === 'normal' ? '0' : '')};
`;

HorizontalLine.propTypes = {
  thickness: PropTypes.string,
  type: PropTypes.string
};

HorizontalLine.defaultProps = {
  thickness: '2x',
  type: 'normal'
};

export default HorizontalLine;
