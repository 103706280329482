import { SET_SESSION } from '../actions/session';

const INITIAL_STATE = {
  session: {},
  expired: false
};

const sessionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_SESSION: {
      const { session } = action;
      return {
        ...state,
        ...session
      };
    }
    default:
      return state;
  }
};

export default sessionReducer;
