export const defaultTnsValidationOptions = {
  tn: {
    isValid: true,
    errorMessage: ''
  },
  cmp: {
    isValid: true,
    errorMessage: ''
  }
};

export const generateNewTnsValidationList = (tns) => {
  const newTnsInitialValidationList = tns.map(() => ({
    tn: { ...defaultTnsValidationOptions.tn },
    cmp: { ...defaultTnsValidationOptions.cmp }
  }));

  return newTnsInitialValidationList;
};
