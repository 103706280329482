import styled from 'styled-components';
import PropTypes from 'prop-types';

function Dropdown(props) {
  const {
    name,
    selectedValue,
    options,
    onChange,
    disabled,
    type,
    isValid,
    errorMessage,
    insideTable,
    leftAlign
  } = props;

  return (
    <DropdownMainContainer type={type}>
      <DropdownContainer>
        <DropdownInputField
          name={name}
          value={selectedValue}
          onChange={onChange}
          disabled={disabled}
          type={type}
          isValid={isValid}
          insideTable={insideTable}
          leftAlign={leftAlign}
        >
          {
            options.map((x) => <OptionField key={x.id} value={x.id}>{x.name}</OptionField>)
          }
        </DropdownInputField>
        {/* {
          !isValid
            ? (<ValidationIndicator>!</ValidationIndicator>)
            : null
        } */}
      </DropdownContainer>
      {
        !isValid
          ? (
            <ValidationMessageContainer type={type} insideTable={insideTable}>
              {errorMessage}
            </ValidationMessageContainer>
          )
          : null
      }
    </DropdownMainContainer>
  );
}

Dropdown.propTypes = {
  name: PropTypes.string,
  selectedValue: PropTypes.number,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  })),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  isValid: PropTypes.bool,
  errorMessage: PropTypes.string,
  insideTable: PropTypes.bool,
  leftAlign: PropTypes.bool
};

Dropdown.defaultProps = {
  name: '',
  selectedValue: -1,
  options: [],
  onChange: () => { },
  disabled: true,
  type: 'normal',
  isValid: true,
  errorMessage: '',
  insideTable: false
};

const DropdownMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${({ type }) => (type === 'normal' ? 'flex-end' : 'flex-start')};
  justify-content: center;
`;

const DropdownContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const DropdownInputField = styled.select`
  font-size: 1em;
  padding: ${({ type }) => (type === 'normal' ? '.15em .5em' : '.3em')};
  font-weight: ${({ type }) => (type === 'normal' ? '600' : '')};
  border: ${({ theme, type }) => (type === 'normal' ? `.125em solid ${theme.dropdown.color}` : `2px solid ${theme.dropdown.color}`)};
  color: ${({ theme }) => theme.dropdown.color};
  background-color: ${({ theme }) => (theme.dropdown.backgroundColor)};
  margin: ${({ type }) => (type === 'normal' ? '0 0 0 1em' : '')};
  text-align: ${({ type, leftAlign }) => (type === 'normal' && !leftAlign ? 'center' : '')};
  cursor: ${({ disabled }) => (!disabled ? 'pointer' : 'not-allowed')};
  border: ${({ isValid }) => (!isValid ? '1px solid red' : '')};
  ${({ insideTable }) => (insideTable ? 'margin: 0;' : '')}  
`;

const ValidationMessageContainer = styled.div`
  color: red;
  font-size: ${({ type }) => (type === 'normal' ? '.7em' : '.85em')};
  text-align: ${({ type }) => (type === 'normal' ? 'right' : 'left')};
  ${({ insideTable }) => (!insideTable ? 'white-space: nowrap;' : '')}  
`;

// const ValidationIndicator = styled.div`
//   text-align: center;
//   width: 1.5em;
//   height: 1.5em;
//   border-radius: .15em;
//   margin: .5em .5em;
//   color: white;
//   background-color: red;
// `;

const OptionField = styled.option``;

export default Dropdown;
