import { SET_LOADING } from '../actions/loading';

const INITIAL_STATE = {
  isLoading: false
};

const loadingReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_LOADING: {
      const { isLoading } = action;
      return {
        ...state,
        isLoading
      };
    }
    default:
      return state;
  }
};

export default loadingReducer;
