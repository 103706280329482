export const GET_CONTACT_INFORMATION = 'GET_CONTACT_INFORMATION';
export const SET_CONTACT_INFORMATION = 'SET_CONTACT_INFORMATION';

export const getContactInformation = () => ({
  type: GET_CONTACT_INFORMATION
});

export const setContactInformation = (contactInfo) => ({
  type: SET_CONTACT_INFORMATION,
  contactInfo
});
