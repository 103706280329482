import { FaSun, FaMoon } from 'react-icons/fa';

const theme = {
  light: {
    fontFamily: 'Lato, sans-serif',
    color: 'black',
    backgroundColor: 'white',
    quoteSection: {
      color: 'black',
      backgroundColor: 'white',
      shadow: 'rgba(0,0,0,0.75)'
    },
    header: {
      color: 'white',
      backgroundColor: '#1b2e63'
    },
    title: {
      linkButton: {
        color: 'blue',
        backgroundColor: 'white'
      },
      horizontalLine: {
        backgroundColor: '#c5c5c5'
      }
    },
    dropdown: {
      color: 'black',
      backgroundColor: 'white'
    },
    table: {
      body: {
        color: 'grey'
      }
    },
    linkButton: {
      color: 'blue'
    },
    footer: {
      color: 'black',
      backgroundColor: 'lightgrey'
    },
    button: {
      backgroundColor: '#0876db'
    },
    themeButton: {
      backgroundColor: 'black',
      color: 'pink',
      shadow: 'rgba(0,0,0,0.75)',
      className: 'fa-solid fa-moon',
      icon: (() => (<FaMoon />))()
    },
    spinner: {
      color: 'white'
    },
    inputField: {
      color: 'black',
      backgroundColor: 'lightgrey',
      disabled: {
        color: 'black',
        backgroundColor: 'lightgrey'
      },
      normal: {
        color: 'black',
        backgroundColor: 'white',
        disabled: {
          color: 'black',
          backgroundColor: 'lightgrey'
        }
      }
    },
    overlay: {
      backgroundColor: 'black'
    },
    popup: {
      color: 'black',
      backgroundColor: 'white',
      link: {
        color: 'blue'
      }
    },
    searchableDropdown: {
      color: 'black',
      backgroundColor: 'white',
      hoverColor: '#0876db'
    },
    icon: {
      color: 'black'
    }
  },
  dark: {
    fontFamily: 'Lato, sans-serif',
    color: 'white',
    backgroundColor: '#333333',
    quoteSection: {
      color: 'white',
      backgroundColor: '#191919',
      shadow: 'black'
    },
    header: {
      color: '#1b2e63',
      backgroundColor: 'lightgrey'
    },
    title: {
      linkButton: {
        color: '#916BBF',
        backgroundColor: 'black'
      },
      horizontalLine: {
        backgroundColor: 'white'
      }
    },
    dropdown: {
      color: 'white',
      backgroundColor: '#191919'
    },
    table: {
      body: {
        color: 'lightgrey'
      }
    },
    linkButton: {
      color: '#916BBF'
    },
    footer: {
      color: 'white',
      backgroundColor: '#333333'
    },
    button: {
      backgroundColor: '#916BBF'
    },
    themeButton: {
      backgroundColor: '#0876db',
      color: 'yellow',
      shadow: 'yellow',
      className: 'fa-solid fa-sun',
      icon: (() => (<FaSun />))()
    },
    spinner: {
      color: '#916BBF'
    },
    inputField: {
      color: 'white',
      backgroundColor: '#333333',
      disabled: {
        color: 'white',
        backgroundColor: '#333333'
      },
      normal: {
        color: 'white',
        backgroundColor: '#191919',
        disabled: {
          color: 'white',
          backgroundColor: '#333333'
        }
      }
    },
    overlay: {
      backgroundColor: 'white'
    },
    popup: {
      color: 'white',
      backgroundColor: '#282828',
      link: {
        color: '#916BBF'
      }
    },
    searchableDropdown: {
      color: 'white',
      backgroundColor: '#191919',
      hoverColor: '#916BBF'
    },
    icon: {
      color: 'white'
    }
  }
};

export default theme;
