import { SET_BY_THE_GIG_SHARED_DATA_DROPDOWN, SET_CURRENT_MOBILE_PROVIDER_DROPDOWN,
  SET_PCAT_DEVICE_DROPDOWN, SET_PCAT_DEVICE_INFO,
  SET_TOTAL_LINES, SET_UNIT_DROPDOWN, SET_UNLIMITED_TYPE_DROPDOWN, SET_UNLIMITED_DROPDOWN, SET_UNLIMITED_INTRO_DROPDOWN, SET_CUSTOMER_NAME } from '../actions/field';

const INITIAL_STATE = {
  totalLines: 0,
  companyName: '',
  unitsDropdown: [],
  byTheGigSharedDataDropdown: [],
  currentMobileProviderDropdown: [],
  pcatDeviceDropdown: [],
  pcatDeviceInfo: [],
  unlimitedTypeDropdown: [],
  unlimitedDropdown: [],
  unlimitedIntroDropdown: [],
};

const fieldReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_TOTAL_LINES: {
      const { totalLines } = action;
      return {
        ...state,
        totalLines
      };
    }
    case SET_UNIT_DROPDOWN: {
      const { unitsDropdown } = action;
      return {
        ...state,
        unitsDropdown
      };
    }
    case SET_BY_THE_GIG_SHARED_DATA_DROPDOWN: {
      const { byTheGigSharedDataDropdown } = action;
      return {
        ...state,
        byTheGigSharedDataDropdown
      };
    }
    case SET_CURRENT_MOBILE_PROVIDER_DROPDOWN: {
      const { currentMobileProviderDropdown } = action;
      return {
        ...state,
        currentMobileProviderDropdown
      };
    }
    case SET_PCAT_DEVICE_DROPDOWN: {
      const { pcatDeviceDropdown } = action;
      return {
        ...state,
        pcatDeviceDropdown
      };
    }
    case SET_PCAT_DEVICE_INFO: {
      const { pcatDeviceInfo } = action;
      return {
        ...state,
        pcatDeviceInfo
      };
    }
    case SET_UNLIMITED_TYPE_DROPDOWN: {
      const { unlimitedTypeDropdown } = action;
      return {
        ...state,
        unlimitedTypeDropdown
      };
    }
    case SET_UNLIMITED_DROPDOWN: {
      const { unlimitedDropdown } = action;
      return {
        ...state,
        unlimitedDropdown
      };
    }
    case SET_UNLIMITED_INTRO_DROPDOWN: {
      const { unlimitedIntroDropdown } = action;
      return {
        ...state,
        unlimitedIntroDropdown
      };
    }
    case SET_CUSTOMER_NAME: {
      const { customerName } = action;
      return {
        ...state,
        customerName
      };
    }

    default:
      return state;
  }
};

export default fieldReducer;
