import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { GrClose } from 'react-icons/gr';
import { setPopUp } from '../../actions/popup';
import { INITIAL_STATE } from '../../reducers/popup';
import { devices } from '../../utils/deviceType';

function PopUp(props) {
  const {
    isOpen,
    title,
    content,
    specialContent,
    type,
    setupPopUp
  } = props;

  return (
    <div>
      {
        isOpen
          ? (
            <>
              <OverlayContainer />
              <PopUpContainer>
                <PopUpDialog type={type}>
                  <TitleContainer>
                    <Title type={type}>{title}</Title>
                    <CloseButtonContainer type={type}>
                      <GrClose onClick={() => setupPopUp({ ...INITIAL_STATE })} width="100%" height="100%" />
                    </CloseButtonContainer>
                  </TitleContainer>
                  <Content type={type}>{type === 'info' ? specialContent : content}</Content>
                </PopUpDialog>
              </PopUpContainer>
            </>
          ) : null
      }
    </div>
  );
}

const OverlayContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 200%;
  background-color: ${({ theme }) => theme.overlay.backgroundColor};
  opacity: 30%;
  z-index: 2;  
  overflow-y: scroll;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const PopUpContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;  
  display: flex;
  justify-content: center;  
  align-items: center;
  z-index: 3;
`;

const PopUpDialog = styled.div` 
  border-radius: 4px;
  max-height: 70%;
  color: ${({ theme }) => theme.popup.color};
  background-color: ${({ theme }) => theme.popup.backgroundColor};
  padding: 1em;
  max-width: ${({ type }) => (type === 'info' ? '55%' : '40%')};
  margin: auto;
  overflow-y: auto;  
  padding: 2em 3em;
  
  path {
    stroke: ${({ theme }) => theme.popup.color};
  }

  a {
    color: ${({ theme }) => theme.popup.link.color}
  }

  @media only screen and ${devices.laptop} {    
    max-width: ${({ type }) => (type === 'info' ? '35%' : '25%')};
  }
`;

const Title = styled.h2`  
  white-space: nowrap;
  padding: 0;
  margin: 0;
  font-weight: ${({ type }) => (type !== 'info' ? '600' : '600')};
  font-size: ${({ type }) => (type !== 'info' ? '1.5em' : '1.75em')};
  line-height: 1.333333333;
  color: ${({ theme }) => theme.popup.color};

  @media only screen and ${devices.laptop} {    
    font-size: ${({ type }) => (type !== 'info' ? '1.6em' : '2em')};
  }
`;

const Content = styled.div`
  font-size: ${({ type }) => (type === 'info' ? '.95em' : '.9em')};
`;

const CloseButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin: 0;
  cursor: pointer;
  font-size: ${({ type }) => (type !== 'info' ? '1.5em' : '1.75em')};
  color: ${({ theme }) => theme.popup.color};
  background-color: ${({ theme }) => theme.popup.backgroundColor};
`;

const TitleContainer = styled.div`
  margin: 0 0 1em 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

PopUp.propTypes = {
  isOpen: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
  specialContent: PropTypes.object,
  type: PropTypes.string,
  setupPopUp: PropTypes.func
};

const mapStateToProps = (state) => ({
  isOpen: state.popUp.title,
  title: state.popUp.title,
  content: state.popUp.content,
  type: state.popUp.type,
  specialContent: state.popUp.specialContent
});

const mapDispatchToProps = {
  setupPopUp: setPopUp
};

export default connect(mapStateToProps, mapDispatchToProps)(PopUp);
