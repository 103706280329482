const API_ENV = window.env?.API_ENV;
const ENV = { ...process.env };
let BASE_API_URL = ENV.REACT_APP_API_BASE_URL.replace(/<API_ENV>/, API_ENV);

// Overriding base url for production
if (API_ENV === 'production') {
  BASE_API_URL = ENV.REACT_APP_API_BASE_URL_PROD;
}

const joinURL = (url) => BASE_API_URL + ENV[`REACT_APP_${url}`];

export const GET_QUOTE = joinURL('GET_QUOTE');
export const SAVE_QUOTE = joinURL('SAVE_QUOTE');
export const VALIDATE_IMEI = joinURL('VALIDATE_IMEI');
export const GET_QUICKQUOTE_CUSTOMER_SESSION = joinURL('GET_QUICKQUOTE_CUSTOMER_SESSION');
export const GET_STANDARD_ADDRESS = joinURL('GET_STANDARD_ADDRESS');
