import { takeLatest } from 'redux-saga/effects';
import { GET_QUOTE, SUBMIT_FINAL_QUOTE, SUBMIT_QUOTE } from '../actions/quote';
import { GET_USERS } from '../actions/user';
import { handleGetQuote, submitFinalQuote, submitQuote } from './quote';
import { handleGetUsers } from './user';

export default function* watcherSaga() {
  yield takeLatest(GET_USERS, handleGetUsers);
  yield takeLatest(GET_QUOTE, handleGetQuote);
  yield takeLatest(SUBMIT_QUOTE, submitQuote);
  yield takeLatest(SUBMIT_FINAL_QUOTE, submitFinalQuote);
}
