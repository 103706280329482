import styled from 'styled-components';
import HorizontalLine from '../utility/horizontalLine';
import { devices } from '../../utils/deviceType';

function Footer() {
  return (
    <FooterContainer>
      <Disclaimer>
        Pricing for devices, taxes, and fees are subject to change during and
        after quoting.
      </Disclaimer>
      <ContactUs>
        <Paragraph>
          After submitting this form, your Comcast Business Sales Representative
          will be in contact to further review your information.
        </Paragraph>
      </ContactUs>
      <HorizontalLine />
    </FooterContainer>
  );
}

const FooterContainer = styled.div`
  width: 80%;
  margin: auto;
  padding: 0;
  margin-top: 50px;

  @media only screen and ${devices.mobileS} {
    width: 90%;
  }

  @media only screen and ${devices.mobileM} {
    width: 90%;
  }

  @media only screen and ${devices.mobileL} {
    width: 90%;
  }

  @media only screen and ${devices.tablet} {
    width: 88%;
  }

  @media only screen and ${devices.laptop} {
    width: 80%;
  }
`;

const Disclaimer = styled.div`
  font-size: 0.875em;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.65em 0;
`;

const ContactUs = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.footer.color};
  background-color: ${({ theme }) => theme.footer.backgroundColor};
  margin-bottom: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.875em;
  padding: 1em 0;
`;

const Paragraph = styled.span`
  text-align: center;
  font-weight: 600;
`;

export default Footer;
