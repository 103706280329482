import { SET_THEME, TOGGLE_THEME } from '../actions/theme';

const INITIAL_STATE = {
  theme: 'light'
};

const themeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_THEME: {
      const { theme } = action;
      return {
        ...state,
        theme
      };
    }
    case TOGGLE_THEME: {
      return {
        ...state,
        theme: state.theme === 'light' ? 'dark' : 'light'
      };
    }
    default:
      return state;
  }
};

export default themeReducer;
