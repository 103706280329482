import styled from 'styled-components';
import { devices } from '../../utils/deviceType';
import { ReactComponent as Logo } from './comcast-business-logo-vector.svg';

function Header() {
  return (
    <HeaderContainer>
      <LogoContainer>
        <LogoSpan>
          <Logo width="100%" height="100%" />
        </LogoSpan>
      </LogoContainer>
      <TitleContainer>
        <Title>REVIEW ACCOUNT DETAILS</Title>
      </TitleContainer>
    </HeaderContainer>
  );
}

const HeaderContainer = styled.div`
  background-color: ${({ theme }) => theme.header.backgroundColor};
  color: ${({ theme }) => theme.header.color};
  width: 100%;
  height: 5em;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  font-weight: 600;  
`;

const LogoContainer = styled.h3`  
  width: 100%;
  padding-left: 1em;
  padding-top: .3em;
  font-family: Montserrat;
  letter-spacing: .05em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  path { 
    fill: white;
  }
`;

const LogoSpan = styled.span`
  width: 10%;
  height: 10%;

  @media only screen and ${devices.mobileS} {
    width: 15%;
  }

  @media only screen and ${devices.mobileM} {
    width: 13.5%;
  }

  @media only screen and ${devices.mobileL} {
    width: 12%;
  }
    
  @media only screen and ${devices.tablet} {
    width: 13.5%;
  }

  @media only screen and ${devices.laptop} {
    width: 10%;
  }
`;

const TitleContainer = styled.div`
  position: absolute;
  width: 70%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Title = styled.h3`  
  font-weight: 600;
  letter-spacing: .05em;
  font-size: 1.23em;
  font-family: Montserrat;
`;

export default Header;
