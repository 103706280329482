import { formatPhoneNumber } from '../../../utils/helper';
import { defaultTnsValidationOptions } from './lines';

const defaultTnOptions = {
  id: 0,
  tn: null,
  cmp: -1,
  isValid: true
};

export const onTnChange = (newTn, i, setState, tns, validationTnsList, setValidationList) => {
  const updatedTnsList = tns.map((tn, index) => {
    if (index === i) {
      const teleNum = { ...tn, tn: { ...tn.tn } };
      // Resetting validation status on change
      const updatedValidationTnsList = [...validationTnsList];
      updatedValidationTnsList[i].tn = { ...defaultTnsValidationOptions.tn };
      setValidationList((state) => ({ ...state, tns: [...updatedValidationTnsList] }));

      if (teleNum.tn === null) {
        teleNum.tn = { displayValue: '', value: '' };
      }

      const plainTnValue = newTn.replace(/[^\d]/g, '');

      if (plainTnValue.length < 11) {
        teleNum.tn.value = plainTnValue;
        teleNum.tn.displayValue = formatPhoneNumber(plainTnValue);
        // reset validity status of the tn
        teleNum.isValid = true;
      }

      return teleNum;
    }
    return tn;
  });

  setState((existingState) => ({
    ...existingState,
    tns: updatedTnsList
  }));
};

export const onCmpChange = (newCmp, i, setState, tns, validationTnsList, setValidationList) => {
  const updatedTnsList = tns.map((tn, index) => {
    if (index === i) {
      const teleNum = { ...tn, tn: { ...tn.tn } };
      // Resetting validation status on change
      const updatedValidationTnsList = [...validationTnsList];
      updatedValidationTnsList[i].cmp = { ...defaultTnsValidationOptions.cmp };
      setValidationList((state) => ({ ...state, tns: [...updatedValidationTnsList] }));

      teleNum.cmp = newCmp;
      // reset validity status of the tn
      teleNum.isValid = true;
      return teleNum;
    }
    return tn;
  });

  setState((existingState) => ({
    ...existingState,
    tns: updatedTnsList
  }));
};

export const createTnInputList = (num, setState, tns, validationTnsList, setValidationList) => {
  let counter = 0;
  let newTnsList = [];
  let newTnsValidationList = [];

  if (tns.length === 0) {
    while (counter < num) {
      newTnsList.push({ ...defaultTnOptions, id: counter });
      newTnsValidationList.push({
        tn: { ...defaultTnsValidationOptions.tn },
        cmp: { ...defaultTnsValidationOptions.cmp }
      });
      counter++;
    }
  } else if (num > tns.length) {
    counter = num - tns.length;
    newTnsList = [...tns];
    newTnsValidationList = [...validationTnsList];
    // to start the counting from
    let id = tns.length;
    while (counter > 0) {
      newTnsList.push({ ...defaultTnOptions, id: id++ });
      newTnsValidationList.push({
        tn: { ...defaultTnsValidationOptions.tn },
        cmp: { ...defaultTnsValidationOptions.cmp }
      });
      counter--;
    }
  } else {
    counter = tns.length - num;
    newTnsList = [...tns];
    newTnsValidationList = [...validationTnsList];
    while (counter > 0) {
      newTnsList.pop();
      newTnsValidationList.pop();
      counter--;
    }
  }

  setState((state) => ({
    ...state,
    tns: newTnsList
  }));

  setValidationList((state) => ({
    ...state,
    tns: newTnsValidationList
  }));
};

export const handlePortTnChange = (num, setState, tns, validationTnsList, setValidationList) => {
  createTnInputList(num, setState, tns, validationTnsList, setValidationList);
  setState((state) => ({
    ...state,
    portTn: num
  }));
};
