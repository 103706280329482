import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { getQuote } from '../actions/quote';
import Header from './header/header';
import Contact from './contact/contact';
import Lines from './lines/lines';
import Devices from './devices/devices';
import CurrentMonthlyBill from './currentMonthlyBill/currentMonthlyBill';
import ThemeButtonBox from './utility/themeButtonContainer';
import Footer from './footer/footer';
import SubmitQuote from './submitQuote';
import Spinner from './utility/spinner';
import PopUp from './utility/popup';
import HorizontalLine from './utility/horizontalLine';
import { devices } from '../utils/deviceType';
import PostalScrubbing from './postalScrubbing/postalScrubbing';

function Quote(props) {
  const {
    getQuoteInfo,
    expired,
    customerName
  } = props;

  const [searchParams] = useSearchParams();

  const [encryptedNotificationId, setEncryptedNotificationId] = useState(null);

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      setEncryptedNotificationId(process.env.REACT_APP_NOTIFICATION_ID);
      return;
    }

    const encryptNotifyId = searchParams.get('s');
    setEncryptedNotificationId(encryptNotifyId);
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    if (expired) {
      navigate('/expired');
    }
  }, [expired, navigate]);

  useEffect(() => {
    if (encryptedNotificationId !== null) {
      if (process.env.NODE_ENV === 'development') {
        getQuoteInfo(encryptedNotificationId);
        return;
      }
      const transformedNotificationId = encodeURIComponent(encryptedNotificationId);
      getQuoteInfo(transformedNotificationId);
    }
  }, [encryptedNotificationId]);

  return (
    <MainContainer>
      <Spinner />
      <PopUp />
      <PostalScrubbing />
      <QuoteContainer>
        <Header />
        <QuoteSubContainer>
          <HeadingContainer>
            {`Let's review your Comcast Business Mobile details, ${customerName || 'First Name'}`}
          </HeadingContainer>
          <HorizontalLine />
          <QuotingSection>
            <Contact />
            <Lines />
            <Devices />
            <CurrentMonthlyBill />
          </QuotingSection>
        </QuoteSubContainer>
        <Footer />
        <SubmitQuote />
        {
          process.env.NODE_ENV !== 'production' ? <ThemeButtonBox /> : ''
        }
      </QuoteContainer>
    </MainContainer>
  );
}

const MainContainer = styled.div`
  background-color: ${({ theme }) => theme.backgroundColor};
`;

const QuoteContainer = styled.div`
  min-width: 100%;
  min-height: 100%;
  margin: auto; 
  bottom: 0;  
  color: ${({ theme }) => theme.quoteSection.color};
  background-color: ${({ theme }) => theme.quoteSection.backgroundColor};
  -webkit-box-shadow: 0px 10px 37px 3px ${({ theme }) => theme.quoteSection.shadow};
  -moz-box-shadow: 0px 10px 37px 3px ${({ theme }) => theme.quoteSection.shadow};
  box-shadow: 0px 10px 37px 3px ${({ theme }) => theme.quoteSection.shadow};
  padding: 0 0 2em 0;

  @media only screen and ${devices.mobileS} {
//      background-color: green !important;
min-width: 100%;
  }

  @media only screen and ${devices.mobileM} {
 //     background-color: tomato !important;
 min-width: 100%;
  }

  @media only screen and ${devices.mobileL} {
 //   background-color: lightgrey !important;
 min-width: 100%;
  }

  @media only screen and ${devices.tablet} {
 //   background-color: purple !important;
 min-width: 90%;
 width: 80%;
  }

  @media only screen and ${devices.laptop} {
 //   background-color: blue !important;
 min-width: 80%;
 width: 80%;
  }

  @media only screen and ${devices.laptopL} {
 //   background-color: lightgreen !important;
 min-width: 70%;
 width: 70%;
  }

  @media only screen and ${devices.desktop} {
 //   background-color: cyan !important;
 min-width: 70%;
 width: 70%;
  }
`;

const QuoteSubContainer = styled.div`  
  width: 100%;
  margin: auto;

  @media only screen and ${devices.mobileS} {
    width: 90%;
  }
    
  @media only screen and ${devices.mobileM} {
    width: 90%;
  }
    
  @media only screen and ${devices.mobileL} {
    width: 90%;
  }
    
  @media only screen and ${devices.tablet} {
    width: 88%;
  }
    
  @media only screen and ${devices.laptop} {
    width: 80%;
  }
`;

const HeadingContainer = styled.h1`
  font-weight: 700;
  margin: 2.2em 0 .5em 0;
  font-size: 1.8em;
  font-family: 'Montserrat';
`;

const QuotingSection = styled.div`
  margin-top: 3em;
`;

Quote.propTypes = {
  getQuoteInfo: PropTypes.func,
  expired: PropTypes.bool,
  customerName: PropTypes.string
};

const mapStateToProps = (state) => ({
  expired: state.sessionInfo?.expired,
  customerName: state.fieldInfo?.customerName
});

const mapDispatchToProps = {
  getQuoteInfo: getQuote
};

export default connect(mapStateToProps, mapDispatchToProps)(Quote);
