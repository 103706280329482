export const ValidateContactInfo = (contactInfo, validationList) => {
  const updatedValidationList = { ...validationList };
  let isValid = true;
  const {
    name,
    businessName,
    address1,
    // address2,
    city,
    state,
    zip,
    email,
    phoneNumber,
    // phoneExt
  } = contactInfo;
  const EMAIL_REGEX = /\S+@\S+\.\S+/;

  // Name Validation
  if (name === '') {
    const updatedNameValidationItem = { ...updatedValidationList.name };
    updatedNameValidationItem.isValid = false;
    updatedNameValidationItem.errorMessage = 'Please enter your name.';
    updatedValidationList.name = updatedNameValidationItem;
    isValid = false;
  }

  if (businessName === '') {
    const updatedBusinessNameValidationItem = { ...updatedValidationList.businessName };
    updatedBusinessNameValidationItem.isValid = false;
    updatedBusinessNameValidationItem.errorMessage = 'Please enter your business/company name.';
    updatedValidationList.businessName = updatedBusinessNameValidationItem;
    isValid = false;
  }

  if (address1 === '') {
    const updatedAddress1ValidationItem = { ...updatedValidationList.address1 };
    updatedAddress1ValidationItem.isValid = false;
    updatedAddress1ValidationItem.errorMessage = 'Please enter your address.';
    updatedValidationList.address1 = updatedAddress1ValidationItem;
    isValid = false;
  }

  if (city === '') {
    const updatedCityValidationItem = { ...updatedValidationList.city };
    updatedCityValidationItem.isValid = false;
    updatedCityValidationItem.errorMessage = 'Please enter your city.';
    updatedValidationList.city = updatedCityValidationItem;
    isValid = false;
  }

  if (state === '' || state.length !== 2) {
    const updatedStateValidationItem = { ...updatedValidationList.state };
    updatedStateValidationItem.isValid = false;
    updatedStateValidationItem.errorMessage = 'Please enter your state/province abbreviation.';
    updatedValidationList.state = updatedStateValidationItem;
    isValid = false;
  }

  if (zip === '' || zip.length !== 5) {
    const updatedZipValidationItem = { ...updatedValidationList.zip };
    updatedZipValidationItem.isValid = false;
    updatedZipValidationItem.errorMessage = 'Please enter your 5-digit ZIP code.';
    updatedValidationList.zip = updatedZipValidationItem;
    isValid = false;
  }

  if (email === '') {
    const updatedEmailValidationItem = { ...updatedValidationList.email };
    updatedEmailValidationItem.isValid = false;
    updatedEmailValidationItem.errorMessage = 'Please enter your email address.';
    updatedValidationList.email = updatedEmailValidationItem;
    isValid = false;
  } else if (!(EMAIL_REGEX.test(email))) {
    const updatedEmailValidationItem = { ...updatedValidationList.email };
    updatedEmailValidationItem.isValid = false;
    updatedEmailValidationItem.errorMessage = 'Please enter a valid email address.';
    updatedValidationList.email = updatedEmailValidationItem;
    isValid = false;
  }

  if (phoneNumber.value === '' || phoneNumber.value.length !== 10) {
    const updatedPhoneNumberValidationItem = { ...updatedValidationList.phoneNumber };
    updatedPhoneNumberValidationItem.isValid = false;
    updatedPhoneNumberValidationItem.errorMessage = 'Please enter your 10-digit phone number.';
    updatedValidationList.phoneNumber = updatedPhoneNumberValidationItem;
    isValid = false;
  }

  if (!isValid) {
    return {
      updatedValidationList,
      isValid
    };
  }

  return {
    isValid
  };
};
