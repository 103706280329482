import { SET_USERS } from '../actions/user';

const INITIAL_STATE = {
  users: []
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_USERS: {
      const { users } = action;
      return {
        ...state,
        users
      };
    }
    default:
      return state;
  }
};

export default userReducer;
