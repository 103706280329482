export const defaultDevicesValidationOptions = {
  device: {
    isValid: true,
    errorMessage: ''
  },
  payment: {
    isValid: true,
    errorMessage: ''
  },
  unit: {
    isValid: true,
    errorMessage: ''
  }
};

export const generateDevicesValidationList = (devices) => {
  const newDevicesValidationList = devices.map(() => ({
    device: { ...defaultDevicesValidationOptions.device },
    payment: { ...defaultDevicesValidationOptions.payment },
    unit: { ...defaultDevicesValidationOptions.unit }
  }));

  return newDevicesValidationList;
};

export const generateByodValidationList = (imei) => {
  const newByodValidationList = imei.map(() => ({
    isValid: true,
    errorMessage: ''
  }));
  return newByodValidationList;
};
