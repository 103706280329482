import { POST } from '../utils/request';
import { VALIDATE_IMEI } from '../utils/url';

export function requestValidateIMEI(imei) {
  try {
    const payload = {
      imeiNumbers: [...imei]
    };
    return POST(VALIDATE_IMEI, payload);
  } catch (err) {
    return Promise.reject(err);
  }
}
