export const PricingInfoContent = {
  title: 'Pricing & Other Info',
  content: (() => (
    <>
      Restrictions apply. Not available in all areas. Requires Comcast Business Internet service.
      Up to 20 lines. Equip., intl. and roaming charges, taxes, and fees, including reg. recovery
      fees, and other charges extra,
      and subj. to change. $25/line/mo. charge applies if one of the following services not
      maintained: Comcast
      Business TV, Internet, or Voice. Pricing subject to change. In times of congestion, your data
      may be temporarily
      slower than other traffic. After 20 GB monthly data use, speeds reduced to a maximum of
      1.5 Mbps download/750
      Kbps upload. A minimum $15.00 charge applies per month, per account for By the Gig lines,
      regardless of data
      usage. Charges apply to each GB or partial GB of shared data. No rollover data. Actual
      savings vary and are
      not guaranteed. For Comcast Business Mobile Disclosures visit:{' '}
      <a href="https://business.comcast.com/learn/mobile/plan" target="_blank" rel="noopener noreferrer" style={{ wordBreak: 'break-all' }}>
        https://business.comcast.com/learn/mobile/plan
      </a>.
      Bring your own device limited to select eligible phones and requires activation
      of new Comcast Business Mobile line.
      Comcast Business Mobile utilizes the highest ranked network from RootMetrics®
      2H 2020 US report.
      WiFi networks not tested. Results may vary. Award is not endorsement.
      Actual speeds vary. © 2022 Comcast. All rights reserved.
    </>
  ))()
};
