import axios from 'axios';
import { call, put } from 'redux-saga/effects';
import { setUsers } from '../actions/user';

function requestGetUsers() {
  try {
    return axios.get('https://jsonplaceholder.typicode.com/users');
  } catch (error) {
    Promise.reject(error);
  }
}

export function* handleGetUsers() {
  try {
    const response = yield call(requestGetUsers);
    const { data } = response;
    yield put(setUsers(data));
  } catch (error) {
    Promise.reject(error);
  }
}
