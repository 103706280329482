export const GET_CURRENT_MONTHLY_BILL = 'GET_CURRENT_MONTHLY_BILL';
export const SET_CURRENT_MONTHLY_BILL = 'SET_CURRENT_MONTHLY_BILL';

export const getCurrentMonthlyBill = () => ({
  type: GET_CURRENT_MONTHLY_BILL
});

export const setCurrentMonthlyBill = (currentMonthlyBill) => ({
  type: SET_CURRENT_MONTHLY_BILL,
  currentMonthlyBill
});
