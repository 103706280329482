import { SET_CONTACT_INFORMATION } from '../actions/contact';

const INITIAL_STATE = {
  name: '',
  businessName: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zip: '',
  email: '',
  phoneNumber: {
    value: '',
    displayValue: ''
  },
  phoneExt: '',
  isValidAddress: false
};

const contactReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CONTACT_INFORMATION: {
      const { contactInfo } = action;
      return {
        ...state,
        ...contactInfo
      };
    }
    default:
      return state;
  }
};

export default contactReducer;
