import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { AiFillMinusSquare, AiFillPlusSquare } from 'react-icons/ai';
import Title from '../utility/title';
import HorizontalLine from '../utility/horizontalLine';
import { devices as deviceTypes } from '../../utils/deviceType';
import Dropdown from '../utility/dropdown';
import SearchableDropdown from '../utility/searchableDropdown';
import { defaultDevicesValidationOptions, generateDevicesValidationList, generateByodValidationList } from '../utility/devices/devices';
import { setDevicesInformation } from '../../actions/devices';
import InputField from '../utility/inputField';
import Byod from './byod';
import { selectByodInfo } from '../../selectors/byod';
import { toggleEditMode } from '../../actions/section';

function Devices(props) {
  const [validationList, setValidationList] = useState({});

  const {
    totalLines,
    devices,
    unitsDropdown,
    pcatDeviceInfo,
    updateDeviceInformation,
    byodInformation,
    editMode,
    switchToEditMode
  } = props;

  const [deviceList, setDeviceList] = useState([]);
  const [byodInfo, setByodInfo] = useState({});

  const setEditMode = (editState) => (
    switchToEditMode({
      devices: {
        editMode: editState
      }
    })
  );

  useEffect(() => {
    let newDeviceValidationList = [];
    let newByodValidationList = [];

    if (devices.length > 0) {
      setDeviceList([...devices]);

      // set initial device validation state
      newDeviceValidationList = generateDevicesValidationList(devices);
    } else {
      const defaultDeviceList = [{
        deviceDetail: null,
        payment: '-1',
        term: 1,
        units: 0
      }];

      setDeviceList(defaultDeviceList);
      newDeviceValidationList = generateDevicesValidationList(defaultDeviceList);
    }

    if (byodInformation?.byod !== undefined) {
      setByodInfo(byodInformation);

      // set initial byod device validation state
      if (byodInformation.imei.length > 0) {
        newByodValidationList = generateByodValidationList(byodInformation.imei);

        // if we got a response from BE saying some of the IMEI numbers are not valid
        if (byodInformation.imei.filter((x) => !x.isValid).length > 0) {
          newByodValidationList = byodInformation.imei.map((x) => {
            if (!x.isValid) {
              return {
                isValid: false,
                errorMessage: 'Please enter a valid IMEI.'
              };
            }
            return {
              isValid: true,
              errorMessage: ''
            };
          });
        }
      }
    }

    setValidationList({
      deviceValidationList: newDeviceValidationList,
      byodValidationList: newByodValidationList
    });
  }, [devices, byodInformation.byod, byodInformation.imei, editMode]);

  // The below code should be moved to store
  const titleText = 'Devices';

  const getDevicePaymentOptions = (device) => {
    if (device.deviceDetail !== null) {
      const deviceDetail = pcatDeviceInfo
        .find((x) => x.productCode === device?.deviceDetail?.id);
      const paymentOptions = deviceDetail?.paymentOptions;
      const paymentDropdown = paymentOptions.map(({ id, name }) => ({ id, name }));
      return paymentDropdown;
    }

    return [
      {
        id: 'ONETIME',
        name: 'Pay in Full'
      },
      {
        id: 'RECURRING',
        name: 'Pay Monthly (for 24 months)'
      }
    ];
  };

  // to update device information
  const updateDevice = (updatedDevice, index) => {
    const updatedDeviceList = deviceList.map((device, i) => {
      if (i === index) {
        const newDevice = {
          deviceDetail: null,
          payment: '-1', // 'ONETIME',
          term: 1,
          units: 0
        };
        if (updatedDevice !== null) {
          newDevice.deviceDetail = {
            id: updatedDevice.productCode,
            name: updatedDevice.name,
            capacity: updatedDevice.capacity
          };
        }
        return newDevice;
      }
      return device;
    });
    setDeviceList(updatedDeviceList);
    // reset validation state
    const updatedValidationList = [...validationList.deviceValidationList];
    updatedValidationList[index].device = {
      isValid: true,
      errorMessage: ''
    };
    setValidationList({
      ...validationList,
      deviceValidationList: updatedValidationList
    });
  };

  // to update device payment information
  const updatePayment = (updatedPayment, index) => {
    const updatedDeviceList = deviceList.map((device, i) => {
      if (i === index) {
        const newDevice = { ...device };
        newDevice.payment = updatedPayment;
        newDevice.term = updatedPayment === 'ONETIME' ? 1 : 24;
        return newDevice;
      }
      return device;
    });
    setDeviceList(updatedDeviceList);

    // reset validation state
    const updatedValidationList = [...validationList.deviceValidationList];
    updatedValidationList[index].payment = {
      isValid: true,
      errorMessage: ''
    };
    setValidationList({
      ...validationList,
      deviceValidationList: updatedValidationList
    });
  };

  // to update device unit information
  const updateUnit = (updatedUnit, index) => {
    const updatedDeviceList = deviceList.map((device, i) => {
      if (i === index) {
        const newDevice = { ...device };
        newDevice.units = updatedUnit;
        return newDevice;
      }
      return device;
    });
    setDeviceList(updatedDeviceList);

    // reset validation state
    const updatedValidationList = [...validationList.deviceValidationList];
    updatedValidationList[index].unit = {
      isValid: true,
      errorMessage: ''
    };
    setValidationList({
      ...validationList,
      deviceValidationList: updatedValidationList
    });
  };

  // to remove a device from the device list
  const removeDevice = (index) => {
    const updatedDeviceList = deviceList.filter((device, i) => (i !== index));
    setDeviceList(updatedDeviceList);

    // reset validation state
    const updatedValidationList = validationList.deviceValidationList.filter((x, i) => i !== index);
    setValidationList({
      ...validationList,
      deviceValidationList: updatedValidationList
    });
  };

  // to add a device to the device list
  const addDevice = () => {
    const newDevice = {
      deviceDetail: null,
      payment: '-1',
      term: 1,
      units: 0
    };
    const updatedDeviceList = [...deviceList, newDevice];
    setDeviceList(updatedDeviceList);
    // add validation state
    const updatedValidationList = [...validationList.deviceValidationList];
    updatedValidationList.push({
      device: { ...defaultDevicesValidationOptions.device },
      payment: { ...defaultDevicesValidationOptions.payment },
      unit: { ...defaultDevicesValidationOptions.unit }
    });

    setValidationList({
      ...validationList,
      deviceValidationList: updatedValidationList
    });
  };

  // revert changes on cancel button click
  const onCancel = () => {
    let newDeviceValidationList = [];
    let newByodValidationList = [];

    if (devices.length > 0) {
      setDeviceList([...devices]);

      // reset to initial byod device validation states
      newDeviceValidationList = generateDevicesValidationList(devices);
    } else {
      const defaultDeviceList = [{
        deviceDetail: null,
        payment: '-1',
        term: 1,
        units: 0
      }];

      setDeviceList(defaultDeviceList);
      newDeviceValidationList = generateDevicesValidationList(defaultDeviceList);
    }

    if (byodInformation?.byod !== undefined) {
      setByodInfo(byodInformation);

      // reset to initial byod device validation state
      if (byodInformation.imei.length > 0) {
        newByodValidationList = generateByodValidationList(byodInformation.imei);
      }
    }

    setValidationList({
      ...validationList,
      deviceValidationList: newDeviceValidationList,
      byodValidationList: newByodValidationList
    });

    setEditMode(false);
  };

  const validateDevicesInfo = () => {
    let isValid = true;
    const deviceInitialValidationList = generateDevicesValidationList(deviceList);
    const updatedDeviceValidationList = [...deviceInitialValidationList];

    const byodInitialValidationList = generateByodValidationList(byodInfo.imei);
    const updatedByodValidationList = [...byodInitialValidationList];

    if (deviceList.length > 0) {
      // validate only if it not the default row
      if (!(deviceList.length === 1 && deviceList[0].deviceDetail === null && deviceList[0].payment === '-1' && deviceList[0].units === 0)) {
        deviceList.map((device, i) => {
          if (device.deviceDetail?.id === null || device.deviceDetail?.id === '' || device.deviceDetail?.id === undefined) {
            const updatedValidationItem = updatedDeviceValidationList[i];
            const updatedDeviceItem = { ...updatedValidationItem.device };
            updatedDeviceItem.isValid = false;
            updatedDeviceItem.errorMessage = 'Please select a device brand.';
            updatedValidationItem.device = updatedDeviceItem;
            isValid = false;
          }
          if (device.payment === null || device.payment === '' || device.payment === undefined || device.payment === '-1') {
            const updatedValidationItem = updatedDeviceValidationList[i];
            const updatedPaymentItem = { ...updatedValidationItem.payment };
            updatedPaymentItem.isValid = false;
            updatedPaymentItem.errorMessage = 'Please select a payment plan.';
            updatedValidationItem.payment = updatedPaymentItem;
            isValid = false;
          }
          if (device.units === 0) {
            const updatedValidationItem = updatedDeviceValidationList[i];
            const updatedUnitItem = { ...updatedValidationItem.unit };
            updatedUnitItem.isValid = false;
            updatedUnitItem.errorMessage = 'Please specify the number of phone devices.';
            updatedValidationItem.unit = updatedUnitItem;
            isValid = false;
          }
          return device;
        });
      }
    }

    if (byodInfo.imei.length > 0) {
      byodInfo.imei.map((item, i) => {
        if (item.imei === '' || item.imei.length < 15) {
          updatedByodValidationList[i] = {
            isValid: false,
            errorMessage: 'Please enter 15-digit IMEI or IMEI2.'
          };
          isValid = false;
        }
        return item;
      });
    }

    if (!isValid) {
      return { isValid, updatedDeviceValidationList, updatedByodValidationList };
    }

    return { isValid };
  };

  // save changes on save button click
  const onSave = () => {
    const validationResult = validateDevicesInfo();
    if (!validationResult.isValid) {
      let updatedDeviceValidationList = [...validationList.deviceValidationList];
      let updatedByodValidationList = [...validationList.byodValidationList];

      if (validationResult?.updatedDeviceValidationList !== undefined) {
        updatedDeviceValidationList = validationResult?.updatedDeviceValidationList;
      }
      if (validationResult?.updatedByodValidationList !== undefined) {
        updatedByodValidationList = validationResult?.updatedByodValidationList;
      }

      setValidationList({
        deviceValidationList: updatedDeviceValidationList,
        byodValidationList: updatedByodValidationList
      });

      return;
    }

    // avoid saving default row
    let finalDeviceList = [];
    if (!(deviceList.length === 1 && deviceList[0].deviceDetail === null)) {
      finalDeviceList = deviceList;
    }

    updateDeviceInformation({
      devices: finalDeviceList,
      byod: byodInfo.byod,
      imei: byodInfo.imei
    });

    setEditMode(false);
    // reset validation state
    const newValidationList = generateDevicesValidationList(deviceList);
    setValidationList({
      ...validationList,
      deviceValidationList: newValidationList
    });
  };

  const displayDeviceSection = () => (
    <>
      <PcatDevicesContainer>
        <PcatDevicesFieldContainer>
          <QuestionContainer>
            How many new devices would you like to purchase? Select the brand and quantity
            for each new device.
          </QuestionContainer>
        </PcatDevicesFieldContainer>
        <PcatDevicesTable>
          <PcatDevicesTableHeading>
            <PcatDevicesTableRow>
              <PcatDevicesTableHeader>Device brand</PcatDevicesTableHeader>
              <PcatDevicesTableHeader>Payment plan</PcatDevicesTableHeader>
              <PcatDevicesTableHeader>Qty</PcatDevicesTableHeader>
            </PcatDevicesTableRow>
          </PcatDevicesTableHeading>
          <PcatDevicesTableBody>
            {
              deviceList.map((device, i) => (
                <PcatDevicesTableRow key={i}>
                  <PcatDevicesTableColumn>
                    {
                      editMode
                        ? (
                          <SearchableDropdown
                            options={pcatDeviceInfo}
                            dropdownSelectedValue={device?.deviceDetail?.name}
                            onChange={(selectedValue) => updateDevice(selectedValue, i)}
                            isValid={validationList.deviceValidationList[i]?.device?.isValid}
                            errorMessage={
                              validationList.deviceValidationList[i]?.device?.errorMessage
                            }
                          />
                        )
                        : (
                          <InputField
                            id={`device-${i}`}
                            name={`device-${i}`}
                            type="text"
                            maxLength={100}
                            placeholder="Select Device Brand"
                            value={device?.deviceDetail?.name}
                            inputFieldStyle="normal"
                            disabled
                          />
                        )
                    }
                  </PcatDevicesTableColumn>
                  <PcatDevicesTableColumn>
                    {
                      editMode
                        ? (
                          <PaymentDropdownContainer>
                            <PaymentsDropdown
                              value={device?.payment}
                              onChange={(e) => updatePayment(e.target.value, i)}
                              isValid={validationList.deviceValidationList[i]?.payment?.isValid}
                            >
                              <option value="-1">Select Payment plan</option>
                              {
                                getDevicePaymentOptions(device)
                                  ?.map((x, index) => (
                                    <option key={index} value={x.id}>{x.name}</option>
                                  ))
                              }
                            </PaymentsDropdown>
                            {
                              !validationList.deviceValidationList[i]?.payment?.isValid
                                ? (
                                  <ValidationMessageContainer>
                                    {validationList.deviceValidationList[i]?.payment?.errorMessage}
                                  </ValidationMessageContainer>
                                )
                                : null
                            }
                          </PaymentDropdownContainer>
                        )
                        : (
                          <InputField
                            id={`payment-${i}`}
                            name={`payment-${i}`}
                            type="text"
                            maxLength={100}
                            placeholder="Select Payment plan"
                            value={device?.payment === '-1' ? '' : (device?.payment === 'ONETIME' ? 'Pay in Full' : 'Pay Monthly (for 24 months)')}
                            inputFieldStyle="normal"
                            disabled
                          />
                        )
                    }
                  </PcatDevicesTableColumn>
                  <PcatDevicesTableColumn>
                    <Dropdown
                      name={`device-${i}`}
                      selectedValue={device?.units}
                      options={unitsDropdown}
                      onChange={(e) => updateUnit(+e.target.value, i)}
                      disabled={!editMode}
                      type="sleek"
                      insideTable
                      isValid={validationList.deviceValidationList[i]?.unit?.isValid}
                      errorMessage={validationList.deviceValidationList[i]?.unit?.errorMessage}
                    />
                  </PcatDevicesTableColumn>
                  <PcatDevicesTableColumn>
                    {
                      editMode
                        ? (
                          i > 0
                            ? (
                              <MinusButton onClick={() => removeDevice(i)}>
                                <AiFillMinusSquare />&nbsp;<ButtonText>Remove</ButtonText>
                              </MinusButton>
                            )
                            : ''
                        )
                        : ''
                    }
                  </PcatDevicesTableColumn>
                </PcatDevicesTableRow>
              ))
            }
            {
              editMode
                ? (
                  deviceList.length < totalLines
                    ? (
                      <PcatDevicesTableRow>
                        <PcatDevicesTableColumn />
                        <PcatDevicesTableColumn />
                        <PcatDevicesTableColumn />
                        <PcatDevicesTableColumn>
                          <PlusButton onClick={addDevice}>
                            <AiFillPlusSquare />&nbsp;<ButtonText>Add More</ButtonText>
                          </PlusButton>
                        </PcatDevicesTableColumn>
                      </PcatDevicesTableRow>
                    )
                    : ''
                )
                : ''
            }
          </PcatDevicesTableBody>
        </PcatDevicesTable>
      </PcatDevicesContainer>
      <HorizontalLine />
      <Byod
        editMode={editMode}
        byodInfo={byodInfo}
        setByodInfo={setByodInfo}
        validationList={validationList}
        setValidationList={setValidationList}
      />
    </>
  );

  return (
    <DevicesContainer>
      <Title
        titleText={titleText}
        editMode={editMode}
        switchToEditMode={() => setEditMode(true)}
        onSave={onSave}
        onCancel={onCancel}
      />
      <DevicesSubContainer>
        {displayDeviceSection()}
        {/* <WorkInProgress size={60} /> */}
      </DevicesSubContainer>
    </DevicesContainer>

  );
}

const DevicesContainer = styled.div`
  margin: 0 0 2em 0;
`;

const DevicesSubContainer = styled.div`
  padding: 1em 4em;

  @media only screen and ${deviceTypes.mobileS} {
    padding: 1em 2em;
  }

  @media only screen and ${deviceTypes.mobileM} {
    padding: 1em 2em;
  }

  @media only screen and ${deviceTypes.mobileL} {
    padding: 1em 2em;
  }

  @media only screen and ${deviceTypes.tablet} {
    padding: 1em 3em;
  }

  @media only screen and ${deviceTypes.laptop} {
    padding: 1em 4em;
  }
`;

const QuestionContainer = styled.p``;

const PcatDevicesContainer = styled.div`
  padding: 0 0 1em 0;
`;

const PcatDevicesFieldContainer = styled.div``;

const PcatDevicesTable = styled.table`
  width: 90%;
  border-spacing: 0 .7em;

  @media only screen and ${deviceTypes.mobileL} {
    width: 100%;
  }
`;

const PcatDevicesTableRow = styled.tr`
  vertical-align: baseline;

  td:last-child {
    width: 8.5em;
  }
`;

const PcatDevicesTableHeader = styled.th`
  font-weight: 500;
`;

const PcatDevicesTableHeading = styled.thead`
  text-align: left;
`;

const PcatDevicesTableBody = styled.tbody`
  color: ${({ theme }) => theme.table.body.color};
`;

const PcatDevicesTableColumn = styled.td`
  padding: 0 1em 0 0;
`;

const PaymentDropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ValidationMessageContainer = styled.div`
  color: red;
  width: 100%;
  font-size: .85em;
  text-align: left;
  white-space: nowrap;
`;

const PaymentsDropdown = styled.select`
  @media only screen and ${deviceTypes.mobileS} {
    width: 10em;
  }
  @media only screen and ${deviceTypes.mobileL} {
    width: 100%;
  }
  font-size: 1em;
  padding: .3em .5em;
  font-weight: 400;
  border: ${({ theme }) => `2px solid ${theme.dropdown.color}`};
  color: ${({ theme }) => theme.dropdown.color};
  background-color: ${({ theme }) => (theme.dropdown.backgroundColor)};
  border: ${({ isValid }) => (!isValid ? '1px solid red' : '')};
  cursor: pointer;
`;

const MinusButton = styled.div`
  display: flex;
  color: ${({ theme }) => (theme.icon.color)};
  cursor: pointer;
  svg {
    height: 1.5em;
    width: 1.5em;
  }
`;

const PlusButton = styled.div`
  display: flex;
  color: ${({ theme }) => (theme.icon.color)};
  cursor: pointer;
  svg {
    height: 1.5em;
    width: 1.5em;
  }
`;

const ButtonText = styled.span`
  display: none;

  @media only screen and ${deviceTypes.mobileL} {
    display: inline;
  }
`;

Devices.propTypes = {
  totalLines: PropTypes.number,
  devices: PropTypes.arrayOf(
    PropTypes.shape({
      deviceDetail: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        capacity: PropTypes.string
      }),
      units: PropTypes.number,
      payment: PropTypes.string,
      term: PropTypes.number
    })
  ),
  unitsDropdown: PropTypes.array,
  pcatDeviceInfo: PropTypes.array,
  updateDeviceInformation: PropTypes.func,
  byodInformation: PropTypes.shape({
    byod: PropTypes.number,
    imei: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      imei: PropTypes.string,
      isValid: PropTypes.bool
    }))
  }),
  editMode: PropTypes.bool,
  switchToEditMode: PropTypes.func
};

const mapStateToProps = (state) => ({
  totalLines: state.fieldInfo?.totalLines,
  devices: state.devicesInfo?.devices,
  unitsDropdown: state.fieldInfo?.unitsDropdown,
  pcatDeviceInfo: state.fieldInfo?.pcatDeviceInfo,
  byodInformation: selectByodInfo(state),
  editMode: state.sectionInfo?.devices?.editMode
});

const mapDispatchToProps = {
  updateDeviceInformation: setDevicesInformation,
  switchToEditMode: toggleEditMode
};

export default connect(mapStateToProps, mapDispatchToProps)(Devices);
