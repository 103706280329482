import PropTypes from 'prop-types';
import styled from 'styled-components';

function QuickQuoteButton(props) {
  const {
    btnText,
    isReadonly,
    onClick,
    buttonAlign
  } = props;

  return (
    <Button
      onClick={onClick}
      buttonAlign={buttonAlign}
      disabled={isReadonly}
    >{btnText}
    </Button>
  );
}

QuickQuoteButton.propTypes = {
  btnText: PropTypes.string,
  isReadonly: PropTypes.bool,
  onClick: PropTypes.func,
  buttonAlign: PropTypes.string
};

const Button = styled.button`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  padding: .5em .5em;
  width: 9em;
  font-weight: 500;
  font-size: 0.9em;
  color: white;
  background-color: ${({ disabled, theme }) => (disabled ? 'grey' : theme.button.backgroundColor)};  
  height: auto;
  text-align: center;
  text-transform: uppercase;
  float: ${({ buttonAlign }) => (buttonAlign ? 'right' : 'left')};
  border: none;
  letter-spacing: 1px;
`;

export default QuickQuoteButton;
