import styled, { keyframes } from 'styled-components';
import { devices } from '../../utils/deviceType';
import ThemeButton from './themeButton';

function ThemeButtonBox() {
  return (
    <ThemeButtonContainer>
      <ThemeButtonMagicContainer>
        <ThemeButton />
      </ThemeButtonMagicContainer>
    </ThemeButtonContainer>
  );
}

const ThemeButtonContainer = styled.div`
  position: fixed;
  bottom: 0em;
  width: 15em;
  height: 10em;
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and ${devices.tablet} {
    left: 0em;
  }

  @media only screen and ${devices.desktop} {
    right: 0em;
  }
`;

const slideFromBottom = keyframes`
  0%{
    transform: translateY(+70px) rotate(0deg);
  }
  100%{
    transform: translateY(0) rotate(360deg);
  }
`;

const ThemeButtonMagicContainer = styled.div`  
  display: none;

  ${ThemeButtonContainer}:hover & {
    display: block;
    animation-name: ${slideFromBottom};
    animation-duration: .7s;
  }
`;

export default ThemeButtonBox;
