import axios from 'axios';
import { setSession } from '../actions/session';

export const GET = async (url, config = {}) => {
  try {
    const response = await axios.get(url, config);
    const isServiceResponse = !!response?.data?.statusCode;
    if (isServiceResponse) {
      const { data } = response;
      const { statusCode } = data;
      if (statusCode === 200) {
        return data;
      }
    }
    if (response.status === 200) {
      return response;
    }
    setSession({ session: {}, expired: true });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const POST = async (url, payload) => {
  try {
    const response = await axios.post(url, payload);
    const isServiceResponse = !!response?.data?.statusCode;
    if (isServiceResponse) {
      const { data } = response;
      const { statusCode } = data;
      if (statusCode === 200) {
        return data;
      }
    }
    if (response.status === 200) {
      return response;
    }
    return Promise.reject();
  } catch (error) {
    return Promise.reject(error);
  }
};
