import { connect } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Dropdown from '../utility/dropdown';
import InputField from '../utility/inputField';
import { onTnChange, onCmpChange, handlePortTnChange } from '../utility/lines/portTn';
import { selectPortTnCmpDropdown } from '../../selectors/portTn';

function PortTn(props) {
  const {
    portingInfo,
    unitsDropdown,
    currentMobileProviderDropdown,
    editMode,
    setState,
    validationList,
    setValidationList
  } = props;

  const {
    portTn,
    tns
  } = portingInfo;

  return (
    <PortTnContainer>
      <PortTnChoiceContainer>
        <PortTnChoiceFieldContainer>
          <QuestionContainer>
            Do you want to keep your existing phone number(s)? If so, how many
            phone numbers would you like to transfer to Comcast Business Mobile?
          </QuestionContainer>
        </PortTnChoiceFieldContainer>
        <PortTnChoiceSelectionContainer>
          <Dropdown
            name="portTn"
            selectedValue={portTn}
            options={unitsDropdown}
            onChange={
              (e) => handlePortTnChange(
                +e.target.value,
                setState,
                tns,
                validationList.tns,
                setValidationList
              )
            }
            disabled={!editMode}
          />
        </PortTnChoiceSelectionContainer>
      </PortTnChoiceContainer>
      {
        portTn > 0
          ? (
            <PortTnEntryContainer>
              <PortTnEntryFieldContainer>
                <Description>
                  Enter in the phone number(s) you&apos;d like to transfer and the
                  mobile provider for each.
                </Description>
              </PortTnEntryFieldContainer>
              <PortTnTable>
                <PortTnTableHeading>
                  <PortTnTableRow>
                    <PortTnTableHeader>Phone number</PortTnTableHeader>
                    <PortTnTableHeader>Current mobile provider</PortTnTableHeader>
                  </PortTnTableRow>
                </PortTnTableHeading>
                {
                  tns.length > 0
                    ? (
                      <PortTnTableBody>
                        {
                          tns.map((x, i) => (
                            <PortTnTableRow key={x.id}>
                              <PortTnTableColumn>
                                <InputField
                                  id={`tn-${i}`}
                                  name={`tn-${i}`}
                                  type="text"
                                  inputType="phoneNumber"
                                  placeholder="Enter Phone Number"
                                  maxLength={14}
                                  isRequired
                                  onChange={
                                    (e) => onTnChange(
                                      e.target.value,
                                      i,
                                      setState,
                                      tns,
                                      validationList.tns,
                                      setValidationList
                                    )
                                  }
                                  value={x.tn?.displayValue}
                                  isValid={validationList?.tns[i]?.tn?.isValid}
                                  errorMessage={validationList?.tns[i]?.tn?.errorMessage}
                                  inputFieldStyle="normal"
                                  disabled={!editMode}
                                />
                              </PortTnTableColumn>
                              <PortTnTableColumn>
                                {
                                  !editMode
                                    ? (
                                      <InputField
                                        id={`cmp-${i}`}
                                        name={`cmp-${i}`}
                                        type="text"
                                        maxLength={100}
                                        value={
                                          currentMobileProviderDropdown
                                            .find((c) => (c.id === x.cmp))?.name ?? ''
                                        }
                                        inputFieldStyle="normal"
                                        disabled={!editMode}
                                      />
                                    )
                                    : (
                                      <Dropdown
                                        id={`cmp-${i}`}
                                        name={`cmp-${i}`}
                                        selectedValue={x.cmp}
                                        options={currentMobileProviderDropdown}
                                        onChange={
                                          (e) => onCmpChange(
                                            +e.target.value,
                                            i,
                                            setState,
                                            tns,
                                            validationList.tns,
                                            setValidationList
                                          )
                                        }
                                        disabled={!editMode}
                                        type="sleek"
                                        isValid={validationList?.tns[i]?.cmp?.isValid}
                                        errorMessage={validationList?.tns[i]?.cmp?.errorMessage}
                                      />
                                    )
                                }

                              </PortTnTableColumn>
                            </PortTnTableRow>
                          ))
                        }
                      </PortTnTableBody>
                    )
                    : null
                }
              </PortTnTable>
            </PortTnEntryContainer>
          )
          : null
      }
    </PortTnContainer>
  );
}

const PortTnContainer = styled.div``;

const PortTnChoiceContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const PortTnChoiceFieldContainer = styled.div``;

const PortTnChoiceSelectionContainer = styled.div``;

const PortTnEntryContainer = styled.div``;

const PortTnEntryFieldContainer = styled.div``;

const QuestionContainer = styled.p``;

const Description = styled.p``;

const PortTnTable = styled.table`
  width: 80%;
  border-spacing: 0 .7em;
`;

const PortTnTableRow = styled.tr`
  vertical-align: baseline;
`;

const PortTnTableHeader = styled.th`
  font-weight: 500;
`;

const PortTnTableHeading = styled.thead`  
  text-align: left;
`;

const PortTnTableBody = styled.tbody`
  color: ${({ theme }) => theme.table.body.color};
`;

const PortTnTableColumn = styled.td`
  padding: 0 1em 0 0;
`;

PortTn.propTypes = {
  portingInfo: PropTypes.shape({
    portTn: PropTypes.number,
    tns: PropTypes.array
  }),
  unitsDropdown: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  })),
  currentMobileProviderDropdown: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  })),
  editMode: PropTypes.bool,
  setState: PropTypes.func,
  validationList: PropTypes.object,
  setValidationList: PropTypes.func
};

const mapStateToProps = (state) => ({
  unitsDropdown: state.fieldInfo?.unitsDropdown,
  currentMobileProviderDropdown: selectPortTnCmpDropdown(state)
});

export default connect(mapStateToProps)(PortTn);
