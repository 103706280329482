import React from 'react';
import ReactDOM from 'react-dom/client';
import preval from 'preval.macro';
import App from './App';

const { REACT_APP_BUILD_VERSION } = process.env;

// avoid printing additional details in the production version
if (window.env?.API_ENV !== 'production') {
  console.info(
    `%c\u00A9 QuickQuote | Customer Mobile Request Form \nBuild Date: ${preval`module.exports = new Date().toLocaleString();`} \nPackage Version: ${REACT_APP_BUILD_VERSION}`,
    'background: #1b2e63; color: white; font-size: medium'
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
