import { call, put } from 'redux-saga/effects';
import axios from 'axios';
import { setQuote } from '../actions/quote';
import { setContactInformation } from '../actions/contact';
import { setLoading } from '../actions/loading';
import { setSession } from '../actions/session';
import { setByTheGigSharedDataDropdown, setCustomerName, setCurrentMobileProviderDropdown, setPcatDeviceDataDropdown, setPcatDeviceInfo, setTotalLines, setUnitDropdown, setUnlimitedDropdown, setUnlimitedIntroDropdown, setUnlimitedTypeDropdown } from '../actions/field';
import { setLinesInformation } from '../actions/lines';
import { GET_QUICKQUOTE_CUSTOMER_SESSION, GET_QUOTE, GET_STANDARD_ADDRESS, SAVE_QUOTE } from '../utils/url';
import { setCurrentMonthlyBill } from '../actions/savings';
import { GET, POST } from '../utils/request';
import { setDevicesInformation } from '../actions/devices';
import { requestValidateIMEI } from './imei';
import { setPopUp } from '../actions/popup';
import { ValidateLinesEqualToDevices, ValidateTnNumbers, ValidateNoSelections } from '../utils/validations/submit';
import { calculateTotalLinesAndDevices } from '../utils/helper';
import { setPostalScrubbingPopUp } from '../actions/postalScrubbingPopup';

function requestGetQuote(notificationId) {
  try {
    const url = `${GET_QUOTE}/${notificationId}`;
    return GET(url);
  } catch (error) {
    return Promise.reject(error);
  }
}

function* requestGetCustomerSession(encryptedNotificationId) {
  try {
    const url = `${GET_QUICKQUOTE_CUSTOMER_SESSION}?s=${encryptedNotificationId}`;

    const response = yield GET(url);
    const { value: { data: customerSession } } = response;

    yield put(setSession({ session: { ...customerSession }, expired: false }));

    axios.interceptors.request.use(
      (req) => {
        const config = req;
        const { token: { accessToken: token } } = customerSession;
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        config.headers['Content-Type'] = 'application/json';
        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );

    return customerSession?.guid;
  } catch (error) {
    return null;
  }
}

function* setDropdownValues(data) {
  try {
    if (data?.totalLines) {
      // 1 Set Company Name
      yield put(setTotalLines(data.totalLines));
      yield put(setCustomerName(data?.customerContactInfo?.name));
      // 1.1. Unit dropdown
      const unitsList = Array.from({ length: data.totalLines + 1 }, (_, i) => ({ id: i === 0 ? 0 : i, name: `${i}` }));
      yield put(setUnitDropdown(unitsList));

      // 1.2. By the gig shared data dropdown
      if (data.byTheGig !== null) {
        const byTheGigList = data.byTheGig.map((x) => ({ id: x.id, name: `${x.id === -1 ? 'None' : x.name}` }));
        yield put(setByTheGigSharedDataDropdown(byTheGigList));
      }

      // 1.3. Current Mobile Provider data dropdown
      if (data.currentMobileProvider !== null) {
        const currentMobileProvider = data.currentMobileProvider.map(
          ({ id, name }) => ({ id, name })
        );
        yield put(setCurrentMobileProviderDropdown(currentMobileProvider));
      }

      // 1.4. Devices data dropdown
      if (data.pcat !== null) {
        const pcatDevices = data.pcat.map(
          ({ productCode, name }) => ({ id: productCode, value: name })
        );
        yield put(setPcatDeviceDataDropdown(pcatDevices));
        yield put(setPcatDeviceInfo(data.pcat));
      }

      // 1.5. Unlimited Type data dropdown
      if (data.unlimitedType !== null) {
        const unlimitedType = data.unlimitedType.map(
          ({ id, name }) => ({ id, name })
        );
        yield put(setUnlimitedTypeDropdown(unlimitedType));
      }

      // 1.6. Unlimited data dropdown
      if (data.unlimited !== null) {
        const unlimited = data.unlimited.map(
          ({ id, name }) => ({ id, name })
        );
        yield put(setUnlimitedDropdown(unlimited));
      }

      // 1.7. Unlimited Intro data dropdown
      if (data.unlimitedIntro !== null) {
        const unlimitedIntro = data.unlimitedIntro.map(
          ({ id, name }) => ({ id, name })
        );
        yield put(setUnlimitedIntroDropdown(unlimitedIntro));
      }
    }
  } catch (error) {
    return Promise.reject();
  }
}

export function* handleGetQuote(payload) {
  try {
    const { encryptedNotificationId } = payload;

    yield put(setLoading(true));

    const notificationId = yield call(requestGetCustomerSession, encryptedNotificationId);

    if (notificationId !== null) {
      const response = yield call(requestGetQuote, notificationId);

      if (response) {
        const { data } = response;

        yield put(setQuote(data));
        // 1. Prepare and set all the dropdown values
        yield setDropdownValues(data);
        // 2. Set all the selected values for all the section
        // 2.1. Set Contact Information
        yield put(setContactInformation(data?.customerContactInfo));
        // 2.2. Set Lines Information
        const linesInfo = {
          unlimitedQuantity: data?.previousQuote?.unlimited?.quantity,
          byTheGigQuantity: data?.previousQuote?.byTheGig?.quantity,
          byTheGigSharedData: data?.previousQuote?.byTheGig?.dataUsage,
          portTn: data?.previousQuote?.tns?.portTn,
          tns: data?.previousQuote?.tns?.tns,
          unlimitedType: data?.previousQuote?.unlimitedType,
          unlimitedIntroQuantity: data?.previousQuote?.unlimitedIntro?.quantity,
          unlimitedPlusQuantity: data?.previousQuote?.unlimitedPlus?.quantity,
          unlimitedPremiumQuantity: data?.previousQuote?.unlimitedPremium?.quantity
        };
        yield put(setLinesInformation(linesInfo));

        // 2.3. Set Devices Information
        const devicesInfo = {
          devices: data?.previousQuote?.devices,
          byod: data?.previousQuote?.byod?.quantity,
          imei: data?.previousQuote?.imei?.imeiNums
        };

        yield put(setDevicesInformation(devicesInfo));

        // 2.4 Set Savings Information
        yield put(setCurrentMonthlyBill(data?.previousQuote?.savings));
      }
    } else {
      throw new Error('NOTIFICATION_ID_IS_NULL');
    }
    yield put(setLoading(false));
  } catch (error) {
    yield put(setSession({ session: {}, expired: true }));
  }
}

function requestSubmitQuote(payload) {
  try {
    return POST(SAVE_QUOTE, {
      notificationId: payload.quote.notificationId,
      previousQuote: JSON.stringify(payload.quote.previousQuote),
      customerContactInfo: JSON.stringify(payload.quote.contactInfo)
    });
  } catch (error) {
    return Promise.reject(error);
  }
}

function requestStandardAddress(payload) {
  try {
    return POST(GET_STANDARD_ADDRESS, {
      addressLine1: payload.quote?.contactInfo?.address1,
      addressLine2: payload.quote?.contactInfo?.address2,
      city: payload.quote?.contactInfo?.city,
      state: payload.quote?.contactInfo?.state,
      zipCode: payload.quote?.contactInfo?.zip
    });
  } catch (error) {
    return Promise.reject(error);
  }
}

export function* submitFinalQuote(payload) {
  const { quote: { previousQuote: { imei: { imeiNums: imei } } } } = payload;

  const { quote: { previousQuote: { tns: { tns: tnslist } } } } = payload;

  const { quote: { contactInfo } } = payload;

  const validateTNResponse = ValidateTnNumbers(tnslist);

  // validate TN numbers
  if (validateTNResponse) {
    if (validateTNResponse.filter((x) => !x.isValid).length !== 0) {
      // some of the imei's are not valid
      yield put(setLinesInformation({
        tns: [...validateTNResponse]
      }));

      yield put(setPopUp({
        isOpen: true,
        type: 'validation',
        title: 'ERROR',
        content: 'The entered phone number(s) is not valid for transfer. Please retry.'
      }));

      yield put(setLoading(false));
      return;
    }
  }

  if (imei.length > 0) {
    const response = yield call(requestValidateIMEI, imei);

    if (response) {
      const { data } = response;

      if (data.filter((x) => !x.isValid).length > 0) { // some of the imei's are not valid
        yield put(setDevicesInformation({
          imei: [...data]
        }));

        yield put(setPopUp({
          isOpen: true,
          type: 'validation',
          title: 'ERROR',
          content: 'The entered IMEI(s) is not valid. Please retry.'
        }));

        yield put(setLoading(false));
        return;
      }
    }
  }

  const resp = yield call(requestSubmitQuote, payload);

  if (resp) {
    const { value: { data: d } } = resp;
    if (d.toLowerCase() === 'success') {
      yield put(
        setPopUp({
          isOpen: true,
          type: 'validation',
          title: 'SUCCESS',
          content: 'Thank you for updating your information. Your Comcast Business Sales Representative will be in contact to further discuss your details.'
        })
      );

      // update the latest contactinfo to state
      yield put(setContactInformation(contactInfo));
      yield put(setCustomerName(contactInfo.name));
      yield put(setLoading(false));
      return;
    }
  }

  yield put(setPopUp({
    isOpen: true,
    type: 'validation',
    title: 'ERROR',
    content: 'We were unable to submit your information due to a technical issue. Please try again.'
  }));
}

export function* submitQuote(payload) {
  try {
    const { totalLines, totalDevices } = calculateTotalLinesAndDevices(payload);

    const { quote: { contactInfo: { businessName } } } = payload;

    // validate for no selections
    if (ValidateNoSelections(totalLines, totalDevices)) {
      yield put(setPopUp({
        isOpen: true,
        type: 'validation',
        title: 'ERROR',
        content: 'There are no selections made. Please review the contact information and select a data plan, number of phone lines, and devices.'
      }));
      yield put(setLoading(false));
      return;
    }

    // check if business name is entered
    if (businessName === null || businessName === '') {
      yield put(setPopUp({
        isOpen: true,
        type: 'validation',
        title: 'ERROR',
        content: 'Please enter your business/company name.'
      }));
      yield put(setLoading(false));
      return;
    }

    // validate Lines = Devices
    if (!ValidateLinesEqualToDevices(totalLines, totalDevices)) {
      yield put(setPopUp({
        isOpen: true,
        type: 'validation',
        title: 'ERROR',
        content: 'Total number of Lines does not match the number of Devices. Please revise the total number of Lines and Devices.'
      }));
      yield put(setLoading(false));
      return;
    }

    // Postal Scrubbing

    if (!payload.quote?.contactInfo?.isValidAddress) {
      const { data: { data: standardAddressRes } } = yield call(requestStandardAddress, payload);

      if (standardAddressRes.length > 0) {
        yield put(setPostalScrubbingPopUp({
          isOpen: true,
          standardAddress: standardAddressRes,
          quote: payload
        }));

        yield put(setLoading(false));
        return;
      }
    }

    yield call(submitFinalQuote, payload);

    yield put(setLoading(false));
  } catch (error) {
    yield put(setPopUp({
      isOpen: true,
      type: 'validation',
      title: 'ERROR',
      content: 'We were unable to submit your information due to a technical issue. Please try again.'
    }));
    yield put(setLoading(false));
  }
}
