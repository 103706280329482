import { SET_POSTAL_SCRUBBING_POPUP } from '../actions/postalScrubbingPopup';

export const INITIAL_STATE = {
  isOpen: false,
  title: 'Standardize Address',
  standardAddress: [],
  quote: {}
};

const postalScrubbingPopUpReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_POSTAL_SCRUBBING_POPUP: {
      const { payload } = action;
      return {
        ...state,
        ...payload
      };
    }
    default:
      return state;
  }
};

export default postalScrubbingPopUpReducer;
