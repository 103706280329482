import { SET_QUOTE } from '../actions/quote';

const INITIAL_STATE = {
  quote: {}
};

const quoteReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_QUOTE: {
      const { quote } = action;
      return {
        ...state,
        quote
      };
    }
    default:
      return state;
  }
};

export default quoteReducer;
