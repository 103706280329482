import { createGlobalStyle } from 'styled-components';
import { devices } from './utils/deviceType';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;    
    font-size: 4px;
  }
  body, input, select, button {
    font-family: 'Poppins', 'Montserrat', 'Roboto', 'Lato', sans-serif;
  }
  
  @media only screen and ${devices.mobileS} {
    body {
      font-size: 9px;
    }
  }

  @media only screen and ${devices.mobileM} {
    body {
      font-size: 10px;
    }
  }

  @media only screen and ${devices.mobileL} {
    body {
      font-size: 11px;
    }
  }

  @media only screen and ${devices.tablet} {
    body {
      font-size: 12px;
    }
  }

  @media only screen and ${devices.laptop} {
    body {
      font-size: 14px;
    }
  }

  @media only screen and ${devices.laptopL} {
    body {
      font-size: 14.5px;
    }
  }

  @media only screen and ${devices.desktop} {
    body {
      font-size: 18px;
    }
  }
`;

export default GlobalStyle;
