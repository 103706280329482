import { connect } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { setLoading } from '../actions/loading';
import { devices } from '../utils/deviceType';
import { getSubmitButtonDisableStatus } from '../selectors/submitQuote';
import { setDevicesInformation } from '../actions/devices';
import { submitQuote } from '../actions/quote';
import { selectLinesInfo } from '../selectors/quote';

function SubmitQuote(props) {
  const {
    showSpinner,
    buttonDisable,
    submitInformation,
    contactInformation,
    linesInformation,
    devicesInformation,
    savingsInformation,
    notificationId
  } = props;

  const onClickHandler = () => {
    if (buttonDisable) {
      return;
    }

    showSpinner(true);

    const finalQuote = {
      unlimitedType: linesInformation.unlimitedType,
      unlimited: {
        quantity: linesInformation?.unlimitedQuantity
      },
      unlimitedIntro: {
        quantity: linesInformation?.unlimitedIntroQuantity
      },
      unlimitedPlus: {
        quantity: linesInformation?.unlimitedPlusQuantity
      },
      unlimitedPremium: {
        quantity: linesInformation?.unlimitedPremiumQuantity
      },
      byTheGig: {
        quantity: linesInformation?.byTheGigQuantity,
        dataUsage: linesInformation?.byTheGigSharedData
      },
      tns: {
        portTn: linesInformation?.portTn,
        tns: linesInformation?.tns
      },
      byod: {
        quantity: devicesInformation?.byod
      },
      imei: {
        imeiNums: devicesInformation?.imei
      },
      devices: devicesInformation?.devices,
      savings: {
        currentMonthlyBill: savingsInformation?.currentMonthlyBill,
        currentMobileProvider: savingsInformation?.currentMobileProvider
      }
    };

    const payload = {
      notificationId,
      previousQuote: finalQuote,
      contactInfo: contactInformation
    };

    submitInformation(payload);

    // setTimeout(() => {
    //   showSpinner(false);
    //   // the below code is for testing purpose
    //   setupPopUp({
    //     isOpen: true,
    //     type: 'validation',
    //     title: 'SUCCESS',
    //     content: 'Quote has been successfully submitted'
    //   });
    // }, 5000);
  };

  return (
    <SubmitQuoteContainer>
      <SubmitButton onClick={onClickHandler} disabled={buttonDisable}>
        SUBMIT INFORMATION
      </SubmitButton>
    </SubmitQuoteContainer>
  );
}

const SubmitQuoteContainer = styled.div`
  width: 80%;
  margin: auto;
  padding: 2em 0;  
  display: flex;
  justify-content: flex-end;

  @media only screen and ${devices.mobileS} {
    width: 90%;
  }
    
  @media only screen and ${devices.mobileM} {
    width: 90%;
  }
    
  @media only screen and ${devices.mobileL} {
    width: 90%;
  }
    
  @media only screen and ${devices.tablet} {
    width: 88%;
  }
    
  @media only screen and ${devices.laptop} {
    width: 80%;
  }
`;

const SubmitButton = styled.div`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  padding: 1em 1em;
  width: 13em;
  font-weight: 500;
  font-size: 0.8750em;
  color: white;
  background-color: ${({ theme }) => theme.button.backgroundColor};  
  height: auto;
  text-align: center;
  text-transform: uppercase;  
  background-color: ${({ disabled }) => (disabled ? 'grey' : '')};
  letter-spacing: 1px;
`;

SubmitQuote.propTypes = {
  showSpinner: PropTypes.func,
  buttonDisable: PropTypes.bool,
  submitInformation: PropTypes.func,
  contactInformation: PropTypes.shape({
    name: PropTypes.string,
    businessName: PropTypes.string,
    address1: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    email: PropTypes.string,
    phoneNumber: PropTypes.shape({
      displayValue: PropTypes.string,
      value: PropTypes.string
    }),
    phoneExt: PropTypes.string
  }),
  savingsInformation: PropTypes.shape({
    currentMonthlyBill: PropTypes.number,
    currentMobileProvider: PropTypes.number
  }),
  devicesInformation: PropTypes.shape({
    devices: PropTypes.arrayOf(
      PropTypes.shape({
        deviceDetail: PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
          capacity: PropTypes.string
        }),
        units: PropTypes.number,
        payment: PropTypes.string,
        term: PropTypes.number
      })
    ),
    byod: PropTypes.number,
    imei: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      imei: PropTypes.string,
      isValid: PropTypes.bool
    }))
  }),
  linesInformation: PropTypes.shape({
    unlimitedQuantity: PropTypes.number,
    byTheGigQuantity: PropTypes.number,
    unlimitedType: PropTypes.number,
    unlimitedIntroQuantity: PropTypes.number,
    unlimitedPlusQuantity: PropTypes.number,
    unlimitedPremiumQuantity: PropTypes.number,
    byTheGigSharedData: PropTypes.number,
    portTn: PropTypes.number,
    tns: PropTypes.array
  }),
  notificationId: PropTypes.string
};

const mapStateToProps = (state) => ({
  buttonDisable: getSubmitButtonDisableStatus(state),
  contactInformation: state.contactInfo,
  linesInformation: selectLinesInfo(state),
  devicesInformation: state.devicesInfo,
  savingsInformation: state?.savingsInfo,
  notificationId: state?.sessionInfo?.session?.guid
});

const mapDispatchToProps = {
  showSpinner: setLoading,
  setDevicesInfo: setDevicesInformation,
  submitInformation: submitQuote
};

export default connect(mapStateToProps, mapDispatchToProps)(SubmitQuote);
