export const selectQuoteInfo = (state) => state.quoteInfo.quote;

export const selectPreviousQuoteInfo = (state) => state.quoteInfo.quote?.previousQuote;

export const selectCustomerContactInfo = (state) => state.quoteInfo.quote?.customerContactInfo;

export function selectUnlimitedLinesQuantity(state) {
  return state.quoteInfo.quote?.previousQuote?.unlimited?.quantity;
}

export function selectByTheGigQuantity(state) {
  return state.quoteInfo.quote?.previousQuote?.byTheGig?.quantity;
}

export function selectByTheGigSharedData(state) {
  return state.quoteInfo.quote?.previousQuote?.byTheGig?.dataUsage;
}

export function selectLinesInfo(state) {
  return state.linesInfo;
  // const {
  //   unlimitedQuantity,
  //   byTheGigQuantity,
  //   byTheGigSharedData
  // } = state.linesInfo;

  // return {
  //   unlimitedQuantity,
  //   byTheGigQuantity,
  //   byTheGigSharedData
  // };
}

export const selectPortTn = (state) => state.quoteInfo.quote?.previousQuote?.tns?.portTn;

export const selectTns = (state) => state.quoteInfo.quote?.previousQuote?.tns?.tns;

export function selectPortingInfo(state) {
  // const portTn = selectPortTn(state);
  // const tns = selectTns(state);

  const {
    portTn,
    tns
  } = state.linesInfo;

  return {
    portTn,
    tns
  };
}
